import { getAsyncInjectors } from 'store/utils';
import ManageRoute from './routes/manage';
import CreateRoute from './routes/create';
import PublishedRoute from './routes/published';
import ObjectListRoute from './routes/create/ObjectListRoute';
import {
  SEGMENT_SYNC_BASE_REDUX_STATE_KEY,
} from 'routes/Segments/containers/SaaSSocialSegmentSyncModal/constants';

export default function createRoutes(store) {
  const { injectReducer, injectSagas } = getAsyncInjectors(store);
  return {
    path: 'segments-beta',
    name: 'segments-beta',
    indexRoute: {
      onEnter: (nextState, replace) => replace('/segments-beta/manage'),
    },
    getComponent(nextState, cb) {
      require.ensure([], (require) => {
        // segments manage
        const manageReducer = require('../Segments/routes/Manage/modules');
        const manageSagas = require('../Segments/routes/Manage/sagas');
        injectReducer('segmentsManage', manageReducer.default);
        injectSagas('segmentsManage', manageSagas.default);

        // published segments
        const sagas = require('../../routes/Settings/routes/PublishedSegments/sagas');
        const reducer = require('../../routes/Settings/routes/PublishedSegments/modules');
        injectReducer('publishedSegments', reducer.default);
        injectSagas('publishedSegments', sagas.default);


        // segments segment
        const segmentReducer = require('../Segments/routes/Segment/modules');
        const segmentSagas = require('../Segments/routes/Segment/sagas');
        injectReducer('segmentsSegment', segmentReducer.default);
        injectSagas('segmentsSegment', segmentSagas.default);

        // segments analytics
        const analyticsReducer = require('../Segments/routes/Analytics/modules');
        const analyticsSagas = require('../Segments/routes/Analytics/sagas');
        injectReducer('segmentAnalytics', analyticsReducer.default);
        injectSagas('segmentAnalytics', analyticsSagas.default);

        const socialSegmentSyncReducer =
          require('../Segments/containers/SaaSSocialSegmentSyncModal/modules');
        const socialSegmentSyncSagas =
          require('../Segments/containers/SaaSSocialSegmentSyncModal/sagas');
        injectReducer(SEGMENT_SYNC_BASE_REDUX_STATE_KEY, socialSegmentSyncReducer.default);
        injectSagas(SEGMENT_SYNC_BASE_REDUX_STATE_KEY, socialSegmentSyncSagas.default);

        const SegmentsAlpha = require('./SegmentsAlpha');
        cb(null, SegmentsAlpha.default);
      }, 'segments-beta');
    },
    childRoutes: [
      ManageRoute(),
      CreateRoute(),
      PublishedRoute(),
      ObjectListRoute(),
    ],
  };
}
