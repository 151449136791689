import { call, select } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { orgSelector } from 'modules/user/selectors';

const { rollbar } = window;

function* logRollbarError(errorMessage) {
  const orgId = yield select(orgSelector);
  if (rollbar) {
    rollbar.error(`${errorMessage} for orgId: ${orgId}`);
  }
}

const FAILED_AUTH = 'FAILED_AUTH';
const OAUTH_COMPLETED = 'COMPLETED';
const OAUTH_POLL_TIMEOUT = 180;
const RATE_LIMIT_EXCEEDED = 'RATE_LIMIT_EXCEEDED';
const INVALID_VERSION = 'INVALID_VERSION';
const PLATFORM_ERROR = 'PLATFORM_ERROR';
const IDENTITY_ERROR = 'IDENTITY_ERROR';
const RESTRICTED_ACCESS = 'RESTRICTED_ACCESS';

/**
 * @description this util abstracts having to repeat this code anywhere oauth implementation
 * was to be done using the backend oauth framework too
 */

/**
 * @description this generator function makes the required api call to get the location for oauth
 * authentication and the sync_source_instance_id that needs to checked for when trying to see
 * if the authentication was successfull or not.
 *
 * @param {*} request Request object passed by saga
 * @param {*} orgId
 * @param {*} postBody payload to be send to authorization endpoint
 * @returns JSON object containing redirection location and sync source instance Id
 */
export function* getOAuthRedirectUrl({ request, postBody }) {
  const orgId = yield select(orgSelector);
  const endpoint = `public/organization/${orgId}/oauth/authorize/authorization/`;

  try {
    const redirectionProperties = yield call(request, endpoint, 'POST', {
      body: JSON.stringify(postBody),
    });
    return {
      error: false,
      redirectionProperties,
    };
  } catch (e) {
    logRollbarError(`Oauth util authorization endpoint failed 
    with errorMessage: ${e.errorMessage} for orgId: ${orgId}`);
    return {
      error: true,
      errorMessage: e.errorMessage,
    };
  }
}

/**
 * @description this generator function opens the popup with location that it gets from
 * the above function in redirection properties and also polls the validation api with
 * the sync_source_instance_id provided to it by the above function. It checks whether
 * the validation has failed or succeeded. It will do so for the duration mentioned as
 * timeoutDuration in  the configs passed to it. In case not passed it polls for 180 seconds only
 * then it times out the session for the popup and closes it.
 *
 * @param {*} redirectionProperties { location, sync_source_instance_id }
 * @param {*} orgId
 * @param {*} request Request object passed by saga
 * @param {*} successCallback generic success callback handler
 * @param {*} errorCallback generic error callback handler
 * @param {number} timeoutDuration timeout duration in seconds
 */
export function* pollOAuth({
  redirectionProperties,
  request,
  successCallback,
  errorCallback,
  timeoutDuration = OAUTH_POLL_TIMEOUT,
}) {
  const orgId = yield select(orgSelector);

  let count = timeoutDuration || OAUTH_POLL_TIMEOUT;

  const endpoint = `public/organization/${orgId}/oauth/authorize/validate_auth_token/` +
  `?sync_source_instance_id=${redirectionProperties.sync_source_instance_id}`;

  /**
   * We are not dispatching any callbacks because actual actions are expected to be
   * sent as callbacks
   */

  try {
    const popupWindow = window.open(
        redirectionProperties.location,
        'foo',
        'width=600, height=600'
      );
    if (popupWindow) popupWindow.focus();
    while (count>1) {
      // add a check for validation stop if no window is found
      const validate_res = yield call(request, endpoint, 'GET');
      if (validate_res === 'error') {
        if (popupWindow) {
          popupWindow.close();
        }
        logRollbarError(`Oauth util validation endpoint for 
        orgId: ${orgId}, unable to login please try again later`);
        errorCallback('Unable to login please try again later!');
        break;
      }
      if (validate_res.authorization_status === IDENTITY_ERROR) {
        popupWindow.close();
        logRollbarError(`Oauth util validation endpoint failed for orgId: ${orgId} 
          and sync_source_instance_id: ${redirectionProperties.sync_source_instance_id} with 
          errorMessage: Identity error`);
        errorCallback(`You are trying to login with a different user, If
         you want to change the integration user please contact our support team.`);
        break;
      }

      if (validate_res.is_valid === true &&
        validate_res.authorization_status === OAUTH_COMPLETED) {
        if (popupWindow) {
          popupWindow.close();
        }
        successCallback('Successfully verified the credentials.');
        break;
      }
      if (validate_res.is_valid === false &&
                      validate_res.authorization_status === INVALID_VERSION) {
        if (popupWindow) {
          popupWindow.close();
        }
        errorCallback(`Failed to validate the credentials, Invalid Versions.
        Please verify the API version try to sign-in again!`);
        break;
      }
      if (validate_res.is_valid === false &&
        validate_res.authorization_status === FAILED_AUTH) {
        if (popupWindow) {
          popupWindow.close();
          logRollbarError(`Oauth util validation 
          endpoint for orgId: ${orgId} and sync_source_instance_id: 
          ${redirectionProperties.sync_source_instance_id} with , invalid credentials`);
          errorCallback('Invalid credentials try to sign-in again!');
          break;
        }
      }
      if (
        validate_res.is_valid === false &&
        validate_res.authorization_status === RATE_LIMIT_EXCEEDED
      ) {
        if (popupWindow) {
          popupWindow.close();
          logRollbarError(`Oauth util validation endpoint failed for orgId: ${orgId} 
          and sync_source_instance_id: ${redirectionProperties.sync_source_instance_id} with 
          errorMessage: API rate limit exceeded`);
          errorCallback('API Rate Limit Exceeded, Failed to Validate the token!');
        }
      }
      if (
        validate_res.is_valid === false &&
        validate_res.authorization_status === PLATFORM_ERROR
      ) {
        if (popupWindow) {
          popupWindow.close();
          logRollbarError(`Oauth util validation endpoint failed for orgId: ${orgId} 
          and sync_source_instance_id: ${redirectionProperties.sync_source_instance_id} with 
          errorMessage: Platform error`);
          errorCallback('Some error occurred, please try again in some time!');
        }
      }
      // Adding Failure case for RESTRICTED_ACCESS
      if (
        validate_res.is_valid === false &&
        validate_res.authorization_status === RESTRICTED_ACCESS
      ) {
        if (popupWindow) {
          popupWindow.close();
          logRollbarError(`Oauth util validation endpoint failed for orgId: ${orgId} 
          and sync_source_instance_id: ${redirectionProperties.sync_source_instance_id} with 
          errorMessage: Restricted Access`);
          errorCallback('Restricted Access error, please try again in some time!');
          break;
        }
      }
      yield delay(3000);
      count -= 3;
    }
    if (count < 1) {
      if (popupWindow) {
        popupWindow.close();
      }
      errorCallback('Session timed out. Failed to validate the credentials.');
    }
  } catch (e) {
    logRollbarError(`Oauth util validation endpoint failed for orgId: ${orgId} 
    and sync_source_instance_id: ${redirectionProperties.sync_source_instance_id} with 
    errorMessage: ${e.errorMessage}`);
    errorCallback(e.errorMessage);
  }
}
