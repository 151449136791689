import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { forEach, get, has, includes, isEmpty } from 'lodash';
import { LoadComponent } from 'HOCS';
import { actions } from '../modules.js';
import {
  orgObjSelector,
  orgPropertiesSelector,
  packagesSelector,
  hasDynamicsIntegrationSelector,
  userAppRolesSelector,
  isSettingsManageRouteSelector,
  isSettingsIframeSelect,
  settingsConfigSelector,
  resetAppSettingRandomSelector,
  isSettingsRouteSelector,
} from 'modules/user/selectors';
import {
  getSettingsMaping,
} from '../routes/Manage/constants.js';
import FlagContext, { useFlags } from 'contexts/FlagContext';
import UserContext from 'contexts/UserContext';
import styles from './Drawer.module.scss';
import { RBACContext } from '@sixsense/rbac';
import { classNames, isSiteLoadedInIframe } from 'utils/utils';
import {
  FoldLeft,
  FoldRight,
} from '@sixsense/core/icons';
import { useSelector, useDispatch } from '@sixsense/core/versioned/react-redux';
import { actions as userActions } from 'modules/user/index';
import { advertisingSubOptionSelector } from '../routes/Manage/selector';
import { getCurrentParams, newParamsNavigate, mergeParamsNavigate } from 'utils/navigate';
import { Body, CollapseBody } from './Drawer.component';
import { withRouter } from 'react-router';

export const DrawerComponent = (props) => {
  const {
    user,
    packages: { zen, predictive },
    properties: { bombora_topic_cap, si_trial },
    customerPackages,
    hasDynamics,
    org,
    userAppRoles,
    drawerContainerCustomStyle,
    drawerType,
    isNavigation,
    router,
  } = props;


  const isSettingsManageRoute = useSelector(isSettingsManageRouteSelector);
  const isSettingsIframe = useSelector(isSettingsIframeSelect);
  const advertisingSubOption = useSelector(advertisingSubOptionSelector);
  const resetAppSettingRandom = useSelector(resetAppSettingRandomSelector);
  const { iFrameContainerURL } = useSelector(settingsConfigSelector);
  const shouldSubscribeToMultiAppEvents = drawerType === 'embedded'
    && (iFrameContainerURL || isSettingsIframe);

  const dispatch = useDispatch();

  const { permissions, isExternalSixsenseUser } = useContext(RBACContext);
  const flags = useFlags();

  const showInfo = {
    permissions,
    flags,
    user,
    zen,
    predictive,
    bombora_topic_cap,
    customerPackages,
    hasDynamics,
    isExternalSixsenseUser,
    org,
    userAppRoles,
    si_trial,
  };

  const settingsMaping = React.useMemo(() =>
    getSettingsMaping(flags, advertisingSubOption, org), [flags, advertisingSubOption, org]);

  const isVisible = ({ requiredPermissions, visibilityCheck }) => {
    if (visibilityCheck && !visibilityCheck(showInfo, isExternalSixsenseUser, permissions)) {
      return false;
    }
    if (
      !isExternalSixsenseUser &&
      requiredPermissions &&
      !requiredPermissions.some((p) => permissions.has(p))
    ) {
      return false;
    }
    return true;
  };

  const {
    isExpandedDrawer,
    expandedItem,
    activeItem,
    activeSubItem,
    currentApp,
  } = useSelector(settingsConfigSelector);

  const setExpandedItem = (val) => {
    dispatch(userActions.setExpandedItem(val));
  };
  const setActiveItem = (val) => {
    dispatch(userActions.setActiveItem(val));
  };
  const setActiveSubItem = (val) => {
    dispatch(userActions.setActiveSubItem(val));
  };
  const setCurrentApp = (val) => {
    dispatch(userActions.setCurrentApp(val));
  };

  const setIsExpandedDrawer = (val) => {
    dispatch(userActions.setIsExpandedDrawer(val));
  };

  const findInnerRouteAndSelectItem = () => {
    // when inner component is called.
    const matchingValues = [];
    forEach(settingsMaping, ({ id: parentId, children }) => {
      forEach(children, ({ id: subId, subOption, location, usePermissions }) => {
        if (location) {
          const link = location(usePermissions);
          if (window.location.href.includes(link)) {
            matchingValues.push({
              parentId,
              subId,
              link,
            });
          }
          // eslint-disable-next-line no-shadow
          forEach(subOption, ({ location, usePermissions }) => {
            if (location) {
              // eslint-disable-next-line no-shadow
              const link = location(usePermissions);
              if (window.location.href.includes(link)) {
                matchingValues.push({
                  parentId,
                  subId,
                  link,
                });
              }
            }
          });
        }
      });
    });
    if (matchingValues.length > 0) {
      // returns object with max length of link;
      const {
        parentId,
        subId,
      } = matchingValues.reduce((prev, current) =>
        (prev && prev.link.length > current.link.length) ? prev : current);
      setExpandedItem(parentId);
      onActiveSubItemClick(parentId, subId);
    } else {
      onActiveSubItemClick(activeItem, activeSubItem);
    }
  };

  const resetAppSettingSelection = (multiAppEventData) => {
    let appName;
    if (has(multiAppEventData, 'appName')) {
      appName = multiAppEventData.appName;
    }
    const expandedItemLocal = 'App Settings';
    const activeItemLocal = 'App Settings';
    const activeSubItemLocal = appName || currentApp || 'abm';

    onActiveSubItemClick(activeItemLocal, activeSubItemLocal, multiAppEventData);
    setExpandedItem(expandedItemLocal);
    setIsExpandedDrawer(true);
    if (!(window.location.href || '').includes('/settings/manage')) {
      newParamsNavigate('/settings/manage', {});
    }
  };

  const handleRouteChange = () => {
    const newPath = router.getCurrentLocation().pathname;
    emitMultiAppEventToParent({
      multiAppEvent: 'ABM_TO_PARENT.ActivePathChanged', // Child (ABM) --> Parent (WF)
      activePath: newPath,
    }); // emit current active path to parent so that parent can show the abm path in URL area
  };


  React.useEffect(() => {
    if (resetAppSettingRandom) {
      resetAppSettingSelection();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetAppSettingRandom]);

  React.useEffect(() => {
    let unSubscribeRouteListener = () => { };
    if (shouldSubscribeToMultiAppEvents) {
      unSubscribeRouteListener = router.listen(handleRouteChange);
    }

    if (isEmpty(currentApp)) {
      setCurrentApp(getCurrentParams(window.location.href).current_app || 'abm');
    }
    const hasManagePath = (window.location.href || '').includes('/settings/manage');
    const hasChildIframeContext = (window.location.href || '').includes('?activeContext');
    if (!hasManagePath || hasChildIframeContext) {
      findInnerRouteAndSelectItem();
      setIsExpandedDrawer(false);
    } else {
      onActiveSubItemClick(activeItem, activeSubItem);
    }

    return () => { // destructor
      emitMultiAppEventToParent({
        multiAppEvent: 'ABM_TO_PARENT.settingsDrawerUnLoaded', // Child (ABM) --> Parent (WF)
      }); // when session timeout occurs, page gets redirected and ABM should notify the parent
      unSubscribeRouteListener();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    let messageListener = () => { };
    if (shouldSubscribeToMultiAppEvents) {
      // Listener - listens to child events
      messageListener = mutliAppEventListner;
      window.addEventListener('message', messageListener, false);

      emitMultiAppEventToParent({
        multiAppEvent: 'ABM_TO_PARENT.settingsDrawerLoaded',
      }); // emit onLoad event to parent to stop loader

    } else {
      window.removeEventListener('message', messageListener);
    }
    return () => window.removeEventListener('message', messageListener);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iFrameContainerURL, isSettingsIframe]);


  /**
   * Read the detailed documentation below -
   * https://6sense.atlassian.net/wiki/spaces/ENG/pages/3165749896/Multi-App+Settings+Framework
   *
   * MULTI APP SETTINGS FRAMEWORK CONCEPT ****************
   * There are 3 contexts (window obj) in settings page implementation
   * 1. Parent Window - ex: workflows.6sense.com/settings  !----
   *      This is just a portal that has an iframe that loads the child window (abm).
   * 2. ABM Window - abm.6sense.com/settings/manage?currentApp='workflows' !----
   *      ABM instance loaded from wf parent. will have another iframe inside this if wf is selected
   *      The inner iframe will load a Child instance that renders wf settings in headless mode
   * 3. Child - wf.6sense.com/settings-headless  !----
   *      Settings detail pages of apps like workflows..
   * ************** ************** *************
   *  type TMultiAppEvent = {
   *     multiAppEvent: any of the below //! Events
   *     iframeRedirectUrl?: string;
   *     abmRedirectUrl?: string;
   *     expandDrawer?: boolean;
   *     appName?: string; // needed only if a parent app want to load another app's settings
   *   }
   //! Events
   //! PARENT_TO_ABM.updateABMDrawerState
   //! CHILD_TO_ABM.updateABMDrawerState
   //! PARENT_TO_ABM.loadAppSettingsListPage
   //! CHILD_TO_ABM_TO_PARENT.dataPassThrough
   //! PARENT_TO_ABM_TO_CHILD.dataPassThrough
   //! PARENT_TO_ABM.redirectToABMSettingsPath
   //! ABM_TO_PARENT.settingsDrawerUnLoaded
   //! ABM_TO_PARENT.settingsDrawerLoaded
   * event.data is of type TMultiAppEvent;
   */
  const mutliAppEventListner = (event) => {
    const multiAppEventData = event.data;
    const isMultiAppEvent = has(multiAppEventData, 'multiAppEvent');
    if (isMultiAppEvent) {
      const multiAppEventName = get(multiAppEventData, 'multiAppEvent');
      switch (multiAppEventName) {
        case 'PARENT_TO_ABM.updateABMDrawerState':
        case 'CHILD_TO_ABM.updateABMDrawerState':
          updateDrawerStateFromEvent(multiAppEventData);
          break;
        case 'CHILD_TO_ABM.unloadSettingsDrawer': // if reload/session timeout event is received
          emitMultiAppEventToParent({
            multiAppEvent: 'ABM_TO_PARENT.settingsDrawerUnLoaded', // transmit to parent
          }); // and let parent handle session timeouts
          break;
        case 'PARENT_TO_ABM.loadAppSettingsListPage':
          // Parent --> ABM
          // emited from Parent when settings icon on topnav is clicked in an app
          resetAppSettingSelection(multiAppEventData);
          emitMultiAppEventToChild(multiAppEventData); // notify child
          // that abm has selected the app in settings left-drawer and expanded the drawer.
          // child should render the app settings list page.
          break;
        case 'PARENT_TO_ABM.redirectToABMSettingsPath':
          // Parent --> ABM
          // emited from Parent if parent window wants to redirect ABM to any internal ABM route
          // selectedApp will be default App in the drawer.
          resetAppSettingSelection(multiAppEventData);
          if (has(multiAppEventData, 'abmRedirectUrl')) {
            newParamsNavigate(get(multiAppEventData, 'abmRedirectUrl'), {});
          }
          updateDrawerStateFromEvent(multiAppEventData);
          break;
        case 'PARENT_TO_ABM_TO_CHILD.dataPassThrough': // PASS THROUGH EVENT.
          emitMultiAppEventToChild(multiAppEventData); // ABM simply transmits data received
          // from parent to child. Make sure not to emit this event from child to avoid
          // infinite loops. Always pass this event from parent.
          updateDrawerStateFromEvent(multiAppEventData);
          break;
        case 'CHILD_TO_ABM_TO_PARENT.dataPassThrough': // PASS THROUGH EVENT.
          emitMultiAppEventToParent(multiAppEventData); // ABM simply transmits data received from
          // child to parent. Make sure not to emit this event from parent to avoid
          // infinite loops.  Always pass this event from child.
          updateDrawerStateFromEvent(multiAppEventData);
          setActivePath(multiAppEventData.activePath);
          break;
        default:
          break;
      }
    }
  };

  // Event emitters. *************************************
  // ABM -> Parent (workflows/SI/...)
  const emitMultiAppEventToParent = (eventData) => { // ABM To Parent window
    try {
      if (isSiteLoadedInIframe() && shouldSubscribeToMultiAppEvents) {
        const activeIframeUrl = iFrameContainerURL ? new URL(iFrameContainerURL) : '';
        const appOrigin = activeIframeUrl ? activeIframeUrl.origin : '*';
        window.parent.postMessage(eventData, appOrigin);
      }
    } catch (e) {
      console.error(e);
    }
  };
  // ABM -> child (workflows)
  const emitMultiAppEventToChild = (eventData) => { // ABM To child window
    try {
      setTimeout(() => {
        const iframeSettingsElement = document.getElementById('settings_iframe_id');
        const activeIframeUrl = iFrameContainerURL ? new URL(iFrameContainerURL) : '';
        const origin = activeIframeUrl ? activeIframeUrl.origin : '';
        if (iframeSettingsElement && origin && shouldSubscribeToMultiAppEvents) {
          iframeSettingsElement.contentWindow.postMessage(eventData, origin);
        }
      }, 0);
    } catch (e) {
      console.error(e);
    }
  };

  const setActivePath = (activePath) => {
    if (activePath && !isSiteLoadedInIframe()) {
      mergeParamsNavigate(window.location.pathname, { activePath });
    }
  };

  const updateDrawerStateFromEvent = (eventData) => {
    const hasDrawerState = has(eventData, 'expandDrawer');
    if (hasDrawerState) {
      const drawerState = get(eventData, 'expandDrawer');
      setIsExpandedDrawer(drawerState);
    }
  };

  const onExpandedItemClick = (id) => {
    if (expandedItem === id) {
      setExpandedItem(null);
    } else {
      setExpandedItem(id);
    }
  };

  const findActiveSubItemObject = (itemId, subItemId) => {
    let selectedSubItem;
    forEach(settingsMaping, ({ id: parentId, children }) => {
      if (itemId === parentId) {
        forEach(children, (subItem) => {
          if (subItemId === subItem.id) {
            selectedSubItem = subItem;
          }
        });
      }
    });
    return selectedSubItem;
  };

  const onActiveSubItemClick = (itemId, subItemId, multiAppEventData) => {
    try {
      setActiveItem(itemId);
      setActiveSubItem(subItemId);
      const {
        subOption,
        title,
        id: subId,
        isIframe,
        getIFrameURL,
      } = findActiveSubItemObject(itemId, subItemId);
      let iFrameURL = getIFrameURL ? getIFrameURL() : '';
      const iframeRedirectUrl = get(multiAppEventData, 'iframeRedirectUrl');
      if (iframeRedirectUrl) {
        iFrameURL = iframeRedirectUrl;
      }
      const activeConfigUrl = iFrameURL ? new URL(iFrameURL) : '';

      if (isIframe) {

        if (isEmpty(iFrameContainerURL)) {
          dispatch(userActions.setIFrameContainerURL(iFrameURL));
          // set iframe src only if we are switching from a nonIframe setting page to iframe page
          // or switching from one app that has iframe setting to another app with iframe setting
          // all child navigations has to be done via events. This is done to avoid frequent
          // reloads when events are emitted / settings are switched.
        } else {
          const activeHost = (new URL(iFrameContainerURL)).host;
          const activeConfigUrlHost = activeConfigUrl ? activeConfigUrl.host : '';
          if (activeHost !== activeConfigUrlHost) {
            dispatch(userActions.setIFrameContainerURL(iFrameURL));
          }
        }
        const isPassThrougEvent = includes(
          get(multiAppEventData, 'multiAppEvent'), 'dataPassThrough');
        if (!isPassThrougEvent && iFrameContainerURL === iFrameURL) {
          // current app is iframeApp, load list page of child
          emitMultiAppEventToChild({
            multiAppEvent: 'ABM_TO_CHILD.loadAppSettingsListPage',
            expandDrawer: true,
          });
        }
        const isInMpIframe = getCurrentParams(
          window.location.href)?.activeContext === 'mapping-profiles';
        if (isInMpIframe) {
          emitMultiAppEventToChild({
            multiAppEvent: 'ABM_TO_CHILD.loadMappingProfilesPage',
            expandDrawer: true,
          });
        }
      } else {
        dispatch(userActions.setIFrameContainerURL(null));
      }
      if (subOption) {
        dispatch(userActions.setActiveSettings({ list: subOption, title, id: subId }));
      }
    } catch (e) {
      console.error('Failed to select sub item in drawer - onActiveSubItemClick');
    }
  };

  const onActiveItemClick = (itemId, subItemId, isIframe, iFrameURL) => {
    setActiveItem(itemId);
    setActiveSubItem(subItemId);
    if (!isIframe) {
      setIsExpandedDrawer(false);
    }
    // This is done to prevent redirection for integrations on calling loadOrg()
    if (itemId === 'Integration Settings') {
      dispatch(userActions.setResetAppSettingRandom(null));
    }
    if (isIframe) {
      dispatch(userActions.setIFrameContainerURL(iFrameURL));
      const isInMpIframe = getCurrentParams(
          window.location.href)?.activeContext === 'mapping-profiles';
      if (isInMpIframe) {
        emitMultiAppEventToChild({
          multiAppEvent: 'ABM_TO_CHILD.loadMappingProfilesPage',
          expandDrawer: true,
        });
      } else if (iFrameContainerURL === iFrameURL) {
        emitMultiAppEventToChild({
          multiAppEvent: 'ABM_TO_CHILD.loadAppSettingsListPage',
          expandDrawer: true,
        });
      }
    } else {
      dispatch(userActions.setIFrameContainerURL(null));
    }
  };

  const titleConversion = (title) => title;

  return (
    <React.Fragment>
      <div
        className={classNames(
          styles.drawerContainer,
          !isExpandedDrawer ? styles.collapsedContainer : null,
          drawerType === 'overlay' ? styles.overlayDrawerContainer : null,
          drawerType === 'embedded' ? styles.embeddedDrawerContainer : null,
          isSettingsManageRoute && drawerType === 'overlay' ? styles.dnone : null,
          !isSettingsManageRoute && drawerType === 'embedded' ?
            styles.dnone : null,
          drawerContainerCustomStyle,
          isSettingsIframe && drawerType === 'overlay' ? styles.isIframeOverlay : null,
          isSettingsIframe && drawerType === 'embedded' ? styles.isIframeEmbedded : null,
          !isNavigation && drawerType === 'overlay' ? styles.leftZero : null,
        )}
        onClick={() => !isExpandedDrawer && setIsExpandedDrawer(true)}
      >
        <span
          className={classNames(styles.foldIconContainer)}
          onClick={() => setIsExpandedDrawer(!isExpandedDrawer)}
          data-6si-id={isExpandedDrawer ? 'fold-left' : 'fold-right'}
          data-6si-name={isExpandedDrawer ? 'fold-left' : 'fold-right'}
        >
          {isExpandedDrawer ? (
            <FoldLeft
              color="#111927"
              className={classNames(styles.icon)}
            />
          ) : (
            <FoldRight
              color="#111927"
              className={classNames(styles.icon)}
            />
          )}
        </span>
        <Body
          isExpandedDrawer={isExpandedDrawer}
          isVisible={isVisible}
          settingsMaping={settingsMaping}
          activeItem={activeItem}
          activeSubItem={activeSubItem}
          expandedItem={expandedItem}
          onActiveItemClick={onActiveItemClick}
          onActiveSubItemClick={onActiveSubItemClick}
          onExpandedItemClick={onExpandedItemClick}
          titleConversion={titleConversion}
        />
        <CollapseBody
          isExpandedDrawer={isExpandedDrawer}
        />
      </div>
    </React.Fragment>);

};

DrawerComponent.propTypes = {
  properties: PropTypes.object,
  packages: PropTypes.any,
  user: PropTypes.any,
  customerPackages: PropTypes.any,
  hasDynamics: PropTypes.bool,
  org: PropTypes.bool,
  userAppRoles: PropTypes.array,
  drawerContainerCustomStyle: PropTypes.any,
  drawerType: PropTypes.oneOf(['overlay', 'embedded']),
  isNavigation: PropTypes.bool,
  router: PropTypes.object,
};

const mapStateToProps = (state) => ({
  loading: false, // ?
  loaded: true,
  org: orgObjSelector(state),
  properties: orgPropertiesSelector(state),
  customerPackages: packagesSelector(state),
  hasDynamics: hasDynamicsIntegrationSelector(state),
  userAppRoles: userAppRolesSelector(state),
});

const manageActions = { ...actions };

const DrawerWrapper = (props) => {
  const isSettingsRoute = useSelector(isSettingsRouteSelector);
  if (!isSettingsRoute) {
    return null;
  }
  return <DrawerComponent {...props} />;
};

export default compose(
  FlagContext.FlagConsumer,
  UserContext.UserConsumer,
  connect(mapStateToProps, manageActions),
  LoadComponent
)(withRouter(DrawerWrapper));
