import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DesignSystemColor, pxToRem } from '@sixsense/core/style';
import { actions } from '../modules';
import { css } from 'styles/emotion';
import {
  getIntegrationStatusesSelector,
  searchKeywordSelector,
  allIntegrationStatusSelector,
  categoryIdToNameMapSelector,
} from '../selectors';
import {
  GENERIC_STATUS_CODES,
  GENRIC_INTEGRATION_STATUS,
  imageMap,
  integrationNameToIDMap,
  ACCESS_DENIED_MSG,
} from '../constants';
import { Flex, Badge, Text, Tooltip } from '@sixsense/core/components';
import Table from '@sixsense/core/components/data-display/Table';
import { capitalize } from 'lodash';
import { BADGE_TYPES } from '../../../constants';
import { browserHistory } from 'react-router';
import { classNames } from 'utils/utils';
// @ts-ignore
import { If } from 'babel-plugin-jsx-control-statements';
import { Lock } from '@sixsense/core/icons';
import { pendoTrack } from '../../../utils';

const styles = {
  image: css({
    width: pxToRem(25),
    height: pxToRem(25),
  }),
  badge: css({
    '[class$="-Badge"]': {
      fontSize: pxToRem(12),
    },
  }),
  name: css({
    cursor: 'pointer',
    fontSize: pxToRem(15),
  }),
  enabled: css({
    color: DesignSystemColor.Blue.DEFAULT,
  }),
  disabled: css({
    color: DesignSystemColor.Gray.DEFAULT,
  }),
  lockIcon: css({
    size: pxToRem(15),
    marginTop: pxToRem(5),
    marginLeft: pxToRem(5),
  }),
  tableBorder: css({
    border: `1px solid ${DesignSystemColor.Gray.LIGHT_3}`,
    borderRadius: pxToRem(6),
    overflow: 'hidden',
  }),
};
const header = [
  {
    name: 'Name',
    type: 'heading',
    width: 'w40',
  },
  {
    name: 'Category',
    type: 'category',
    width: 'w40',
  },
  {
    name: 'Status',
    type: 'status',
    width: 'w20',
  },
];

const statusBadgesMap = {
  [GENRIC_INTEGRATION_STATUS.ERROR]: BADGE_TYPES.FAILURE,
  [GENRIC_INTEGRATION_STATUS.ACTIVE]: BADGE_TYPES.SUCCESS,
  [GENRIC_INTEGRATION_STATUS.NOT_STARTED]: BADGE_TYPES.PENDING,
  [GENRIC_INTEGRATION_STATUS.IN_PROGRESS]: BADGE_TYPES.PENDING,
  [GENRIC_INTEGRATION_STATUS.SYNC_DISABLED]: BADGE_TYPES.PENDING,
  [GENRIC_INTEGRATION_STATUS.ENABLED]: BADGE_TYPES.SUCCESS,
};

const TableRow = (data, type, categoryIdToNameMap) => {
  switch (type) {
    case 'status':
      return (
        <div className={styles.badge} id={`${data.name}_validation_status`}>
          <Badge
            mode="light"
            // @ts-ignore
            color={statusBadgesMap[GENRIC_INTEGRATION_STATUS[data.status]]}
          >
            {GENRIC_INTEGRATION_STATUS[data.status]}
          </Badge>
        </div>
      );
    case 'heading':
      return (
        <Flex gap={10} id={`${data.name}_integration_name`}>
          <img
            src={`${
              imageMap[data.name] ? imageMap[data.name] : imageMap.default
            }`}
            alt={`${data.name}_image`}
            className={styles.image}
          />
          <Text.Body
            size="md"
            weight="semibold"
            color={DesignSystemColor.Gray.DARK_4}
          >
            {data.label ? data.label : capitalize(data.name)}
          </Text.Body>
          <If condition={data.hidden}>
            <Badge mode="dark" color="gray" icon={Lock}>
              <Text size={12}>Locked</Text>
            </Badge>
          </If>

        </Flex>
      );
    case 'category':
      return (
        <Text.Body size="md" color={DesignSystemColor.Gray.DARK_1}>
          {categoryIdToNameMap[data.category]}
        </Text.Body>
      );
    default:
      return <></>;
  }
};

export const integrationsWithoutStatus = (integrationId) =>
  [
    integrationNameToIDMap.facebook,
    integrationNameToIDMap.linkedin_segments,
    integrationNameToIDMap.linkedin_advertising,
    integrationNameToIDMap.googleAds,
    integrationNameToIDMap.PeerSpot,
    integrationNameToIDMap.buyer_discovery,
    integrationNameToIDMap.TechTarget,
  ].includes(integrationId);

const MyIntegrations = ({
  integrations,
  allIntegrationStatus,
  categoryIdToNameMap,
}) => {
  // to be removed once advertising integrations apis can be called internally
  const integrationStatuses = allIntegrationStatus;
  const integrationsTable = integrations;
  integrations.forEach((integration, key) => {
    if (integrationsWithoutStatus(integration.id)) {
      integrationStatuses[integration.name] = {
        integration_status:
          GENERIC_STATUS_CODES[integration.successState] || 'NOT_STARTED',
        sync_source_type_id: integration.id,
      };
      integrationsTable[key] = {
        ...integration,
        status: GENERIC_STATUS_CODES[integration.successState] || 'NOT_STARTED',
      };
    } else {
      integrationsTable[key] = {
        ...integration,
        status: allIntegrationStatus[integration.name].integration_status,
      };
    }
  });

  return (
    <Flex direction="column">
      <Table
        rowKey={(data: any) => `${data.name}_${data.source_category?.toLowerCase()}`}
        data={integrationsTable.filter((integration) =>
          [
            'IN_PROGRESS',
            'ACTIVE',
            'ERROR',
            'SYNC_DISABLED',
            'ENABLED',
          ].includes(integration.status)
        )}
        emptyDescription="Please try again."
        emptyIllustration="NoData"
        emptyTitle="Oops, there are no integrations to show!"
        onSelect={() => {}}
        className={styles.tableBorder}
      >
        {header.map(({ name, type, width }) => (
          <Table.Column key={name} header={name}>
            {({ data }: any) => (
              <Table.Cell
                id={`${data.name}_integration`}
                className={classNames(
                  styles.name,
                  width,
                  data.hidden || data.disabled
                    ? styles.disabled
                    : styles.enabled
                )}
                onClick={() =>
                  data.hidden || data.disabled
                    ? ''
                    : browserHistory.push(data.path)
                }
              >
                <Tooltip
                  overlay={
                    // eslint-disable-next-line no-nested-ternary
                    data.hidden
                      ? ACCESS_DENIED_MSG
                      : data.disabled
                      ? data.disabledMessage
                      : ''
                  }
                  placement={'top'}
                >
                  <div
                    data-6si-name="table-cell--integration-row"
                    data-6si-id={`manage-${data.source_category}-${data.id}-${data.name}`}
                    onClick={() =>
                      data.hidden || data.disabled
                        ? ''
                        : (
                          browserHistory.push(data.path),
                          pendoTrack('Integrations-Manage-My-Integrations-Integration-Row', {
                            integrationName: data.name,
                            integrationId: data.id,
                            integrationSourceCategory: data.source_category,
                            integrationSourceType: data.source_type,
                          })
                        )
                    }
                    className={styles.name}
                  >
                    {TableRow(data, type, categoryIdToNameMap)}
                  </div>
                </Tooltip>
              </Table.Cell>
            )}
          </Table.Column>
        ))}
      </Table>
    </Flex>
  );
};

MyIntegrations.propTypes = {
  integrations: PropTypes.array,
  allIntegrationStatus: PropTypes.object,
  categoryIdToNameMap: PropTypes.object,
};

const mapStateToProps = (state) => ({
  integrations: getIntegrationStatusesSelector(state),
  searchKeyword: searchKeywordSelector(state),
  allIntegrationStatus: allIntegrationStatusSelector(state),
  categoryIdToNameMap: categoryIdToNameMapSelector(state),
});

export default connect(mapStateToProps, actions)(MyIntegrations);
