import { useState } from 'react';
import { get, has } from 'lodash';
import { AUDIENCE_ROUTES, MULTI_APP_EVENT } from '../constants';
import { emitMultiAppEventToChild, setActiveSearchParam } from '../utils';


export const useABMMultiAppEventListeners = () => {
  const [appHeaderProps, setAppHeaderProps] = useState({
    title: AUDIENCE_ROUTES.DEFAULT.TITLE,
  });
  const [audienceIframeLoading, setAudienceIframeLoading] = useState(true);

  const triggerMouseDownEvent = () => {
    const mousedownEvent = new MouseEvent('mousedown', {
      bubbles: true,
      cancelable: true,
      view: window,
    });
    document.dispatchEvent(mousedownEvent);
  };

  const mutliAppEventListener = (event) => {
    const multiAppEventData = event.data;
    const isMultiAppEvent = has(multiAppEventData, 'multiAppEvent');
    if (isMultiAppEvent) {
      const multiAppEventName = get(multiAppEventData, 'multiAppEvent');
      switch (multiAppEventName) {
        case MULTI_APP_EVENT.LISTENERS.CHILD_TO_ABM_TO_PARENT_DOCUMENT_CLICKED:
          triggerMouseDownEvent();
          break;
        case MULTI_APP_EVENT.LISTENERS.CHILD_TO_ABM_TO_PARENT_DATA_PASSTHROUGH:
          // ActivePathChanged event
          if (multiAppEventData?.activePath) {
            setActiveSearchParam({ activePath: multiAppEventData?.activePath });
          }
          if (get(multiAppEventData, 'appMounted')) {
            // spinner deactivate
            setAudienceIframeLoading(false);
          }
          break;
        case MULTI_APP_EVENT.LISTENERS.CHILD_TO_ABM_HEADLESS_APP_UNLOADED: {
          // headless app unloaded. prbably due to session timeout. reload to login.
          window.location.reload();
          break;
        }
        case MULTI_APP_EVENT.LISTENERS.CHILD_TO_ABM_APPHEADER_CONFIG: {
          const { backBtnClickEvtName, ...rest } = multiAppEventData?.appHeaderConfig;
          const backBtnClickHandler = backBtnClickEvtName ? () => emitMultiAppEventToChild({
            multiAppEvent: backBtnClickEvtName,
          }) : undefined;
          setAppHeaderProps({
            ...rest,
            onBackBtnCick: backBtnClickHandler,
          });
          break;
        }
        default:
          break;
      }
    }
  };

  return {
    appHeaderProps,
    mutliAppEventListener,
    audienceIframeLoading,
  };
};
