// eslint-disable-next-line max-len
import { GOOGLE_ADS_INTEGRATION_ROUTE } from 'routes/Settings/routes/Integration/routes/SocialAdsIntegration/routes/GoogleAdsIntegration/constants';
// eslint-disable-next-line max-len
import { LINKEDIN_SEGMENT_SYNC_INTEGRATION_ROUTE as LINKEDIN_ADS_INTEGRATION_ROUTE } from 'routes/Settings/routes/Integration/routes/SocialAdsIntegration/routes/LinkedinAdsIntegration/constants';
// eslint-disable-next-line max-len
import { FACEBOOK_ADS_INTEGRATION_ROUTE } from 'routes/Settings/routes/Integration/routes/SocialAdsIntegration/routes/FacebookAdsIntegration/constants';
import { startCase } from 'lodash';
import { AD_NETWORKS, AD_NETWORK_LABELS } from 'constants/campaigns';

export const SEGMENT_SYNC_BASE_REDUX_STATE_KEY = 'segmentSync';

export const SEGMENT_SYNC_REDUX_STATE_KEY = {
  [AD_NETWORKS.google]: 'googleSync',
  [AD_NETWORKS.linkedin]: 'linkedinSync',
  [AD_NETWORKS.facebook]: 'facebookSync',
};

export const SEGMENT_SYNC_ACTION_PREFIX = 'SEGMENTS/SEGMENT_SYNC';
export const GOOGLE_SYNC_ACTION_PREFIX = 'SEGMENTS/GOOGLE_SYNC';
export const LINKEDIN_SYNC_ACTION_PREFIX = 'SEGMENTS/LINKEDIN_SYNC';
export const FACEBOOK_SYNC_ACTION_PREFIX = 'SEGMENTS/FACEBOOK_SYNC';

export const SEGMENT_SYNC_TOGGLE_STATUS = {
  ON: 'On',
  OFF: 'Off',
};

export const SEGMENT_SYNC_STATUS = {
  syncing: 'syncing',
  synced: 'synced',
  inactive: 'inactive',
  failed: 'failed',
};

export const SOCIAL_INTEGRATION_SETTINGS_PAGE = {
  [AD_NETWORKS.google]: `/settings/integration/${GOOGLE_ADS_INTEGRATION_ROUTE}`,
  [AD_NETWORKS.linkedin]: `/settings/integration/${LINKEDIN_ADS_INTEGRATION_ROUTE}`,
  [AD_NETWORKS.facebook]: `/settings/integration/${FACEBOOK_ADS_INTEGRATION_ROUTE}`,
};

export const AD_ACCOUNT_DISABLED_TOOLTIP = `To sync the segment, make sure your ad account 
is enabled in Integrations.`;

export const AD_ACCOUNT_ACCESS_DENIED_TOOLTIP = 'You don\'t have access to this ad account.';

export const AD_ACCOUNT_NOT_SELECTED_TOOLTIP = 'This ad account has not been selected for syncing.';

export const AD_ACCOUNT_SYNCING_TOOLTIP = `Segment is in syncing state, try after sync 
is completed`;

export const SOCIAL_SEGMENT_SYNC_STATUS_KEY = 'ad_network_segment_sync_status';

export const SEGMENT_SYNC_MATCH_RATE_TITLE = {
  [AD_NETWORKS.google]: 'Google\'s Match Rate',
  [AD_NETWORKS.linkedin]: 'Accounts Match',
};

export const SEGMENT_SYNC_AD_NETWORK_CONSENT_KEY = {
  [AD_NETWORKS.google]: 'google_consent',
  [AD_NETWORKS.facebook]: 'facebook_consent',
};

export const SEGMENT_SYNC_LAST_REFRESH_TOOLTIP_TEXT = {
  [AD_NETWORKS.google]: `This date reflects the last automatic contact refresh 
    by 6sense`,
  [AD_NETWORKS.linkedin]: `This date reflects the last automatic account refresh 
    by 6sense`,
  [AD_NETWORKS.facebook]: `This date reflects the last automatic contact refresh 
    by 6sense`,
};

export const SEGMENT_SYNC_ACCOUNT_DROPDOWN_TITLE = {
  [AD_NETWORKS.google]: `${startCase(AD_NETWORKS.google)}`,
  [AD_NETWORKS.linkedin]: AD_NETWORK_LABELS[AD_NETWORKS.linkedin],
  [AD_NETWORKS.facebook]: AD_NETWORK_LABELS[AD_NETWORKS.facebook],
};

export const SEGMENT_SYNC_ZERO_CONTACT_CHECK_MESSAGE = {
  [AD_NETWORKS.google]: `The selected segment has fetched zero contact matches 
  from your connected CRM and/or MAP.`,
  [AD_NETWORKS.facebook]: 'Segment with zero contacts cannot be synced',
};
