import React from "react";
import { classNames } from "utils/utils";
import styles from "./NeedActionIndicator.module.scss";
import PropTypes from "prop-types";
import { AUDIT_TRAIL_FAILED_MSG } from "../../constants";
import {
  LINKEDIN_POSTER_ACCESS_REJECTED,
  LINKEDIN_POSTER_ACCESS_REVOKED,
} from "utils/constants";
import { Flex, Text } from "@sixsense/core/components";
import { ThemeColor } from "@sixsense/core/style/variables";
import { AlertCircle, AlertTriangle } from "@sixsense/core/icons";

const NeedActionIndicator = ({ className, type }) => (
  <div className={classNames(styles.needActionIndicatorContainer, className)}>
    {type === "warning" ? (
      <AlertTriangle color={`var(${ThemeColor.Warning.DEFAULT})`} />
    ) : (
      <AlertCircle color={`var(${ThemeColor.Error.DEFAULT})`} />
    )}
  </div>
);
NeedActionIndicator.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf(["error", "warning"]),
};

export default NeedActionIndicator;

export const summaryTooltip = (needActionIndicators) => {
  if (
    needActionIndicators.length === 1 &&
    [
      AUDIT_TRAIL_FAILED_MSG,
      LINKEDIN_POSTER_ACCESS_REJECTED,
      LINKEDIN_POSTER_ACCESS_REVOKED,
    ].includes(needActionIndicators[0].message)
  ) {
    return null;
  }

  return (
    <ul
      className={classNames(
        styles.needActionTooltipTitle,
        needActionIndicators.length === 1 && styles.removeLeftPadding
      )}
    >
      {needActionIndicators.map(({ message, tooltip }) => (
        <Flex className={styles.tooltipContainer} direction="column">
          {needActionIndicators.length > 1 && (
            <li>
              <Text>{message}</Text>
            </li>
          )}
          {tooltip && (
            <Flex direction="column">
              <Text>{message}</Text>
              <Text>{tooltip}</Text>
            </Flex>
          )}
        </Flex>
      ))}
    </ul>
  );
};
