import { set, clone } from 'lodash';
import {
  L_USER,
  USER,
  OWNER,
  STAFF,
  INTERNAL_USER,
  SUPER_USER,
  DEV,
} from 'routes/Settings/routes/UserManagementV2/constants';
import { getCurrentParams } from 'utils/navigate';
import { isSiteLoadedInIframe } from 'utils/utils';

const initialState = {
  user: {},
  loading: false,
  loaded: false,
  error: false,
  errorMessage: '',
  organization: {
    loading: true,
    loaded: false,
    error: false,
  },
  staffUser: false,
  multiAppsList: [],
  settingsConfig: {
    isSettingsIframe: isSiteLoadedInIframe(),
    isSettingsRoute: false,
    isSettingsManageRoute: false,
    activeSettings: {
      list: [],
      title: '',
      id: getCurrentParams(window.location.href).current_app || 'abm',
    },
    expandedItem: 'App Settings',
    activeItem: 'App Settings',
    activeSubItem: getCurrentParams(window.location.href).current_app || 'abm',
    isExpandedDrawer: true,
    resetAppSettingRandom: null,
    iFrameContainerURL: null,
    currentApp: '',
  },
};

const SET_IFRAME_CONTAINER_URL = 'GLOBAL/SET_IFRAME_CONTAINER_URL';
const setIFrameContainerURL = (iFrameContainerURL) => ({
  type: SET_IFRAME_CONTAINER_URL,
  iFrameContainerURL,
});

const SET_RESET_APP_SETTING_RANDOM = 'GLOBAL/SET_RESET_APP_SETTING_RANDOM';
const setResetAppSettingRandom = (resetAppSettingRandom) => ({
  type: SET_RESET_APP_SETTING_RANDOM,
  resetAppSettingRandom,
});

const SET_IS_EXPANDED_DRAWER = 'GLOBAL/SET_IS_EXPANDED_DRAWER';
const setIsExpandedDrawer = (isExpandedDrawer) => ({
  type: SET_IS_EXPANDED_DRAWER,
  isExpandedDrawer,
});

const SET_EXPANDED_ITEM = 'GLOBAL/SET_EXPANDED_ITEM';
const setExpandedItem = (expandedItem) => ({
  type: SET_EXPANDED_ITEM,
  expandedItem,
});

const SET_ACTIVE_ITEM = 'GLOBAL/SET_ACTIVE_ITEM';
const setActiveItem = (activeItem) => ({
  type: SET_ACTIVE_ITEM,
  activeItem,
});

const SET_ACTIVE_SUB_ITEM = 'GLOBAL/SET_ACTIVE_SUB_ITEM';
const setActiveSubItem = (activeSubItem) => ({
  type: SET_ACTIVE_SUB_ITEM,
  activeSubItem,
});

const SET_CURRENT_APP = 'GLOBAL/SET_CURRENT_APP';
const setCurrentApp = (currentApp) => ({
  type: SET_CURRENT_APP,
  currentApp,
});


const SET_ACTIVE_SETTINGS = 'GLOBAL/SET_ACTIVE_SETTINGS';
const setActiveSettings = (activeSettings) => ({
  type: SET_ACTIVE_SETTINGS,
  activeSettings,
});

const SET_IS_SETTINGS_IFRAME = 'GLOBAL/SET_IS_SETTINGS_IFRAME';
const setIsSettingsIframe = (isSettingsIframe) => ({
  type: SET_IS_SETTINGS_IFRAME,
  isSettingsIframe,
});

const SET_IS_SETTINGS_ROUTE = 'GLOBAL/SET_IS_SETTINGS_ROUTE';
const setIsSettingsRoute = (isSettingsRoute) => ({
  type: SET_IS_SETTINGS_ROUTE,
  isSettingsRoute,
});

const SET_IS_SETTINGS_MANAGE_ROUTE = 'GLOBAL/SET_IS_SETTINGS_MANAGE_ROUTE';
const setIsSettingsManageRoute = (isSettingsManageRoute) => ({
  type: SET_IS_SETTINGS_MANAGE_ROUTE,
  isSettingsManageRoute,
});

const LOAD_USER = 'USER/LOAD';
const LOAD_USER_SUCCESS = 'USER/SUCCESS';
const LOAD_USER_FAIL = 'USER/ERROR';

const roleTranslation = {
  LIMITEDUSER: L_USER,
  USER: USER, //eslint-disable-line
  OWNER: OWNER, // eslint-disable-line
  STAFF: STAFF, // eslint-disable-line
  INTERNAL: INTERNAL_USER,
  SUPERUSER: SUPER_USER,
  DEV,
};

function load() {
  return { type: LOAD_USER };
}

function loadSuccess(user) {
  return { type: LOAD_USER_SUCCESS, user };
}

function loadFail() {
  return { type: LOAD_USER_FAIL };
}

const LOAD_ORG_REQUEST = 'USER/LOAD_ORG_REQUEST';
const LOAD_ORG_SUCCESS = 'USER/LOAD_ORG_SUCCESS';
const LOAD_ORG_FAILURE = 'USER/LOAD_ORG_FAILURE';

function loadOrg() {
  return { type: LOAD_ORG_REQUEST };
}

function loadOrgSuccess(org) {
  return { type: LOAD_ORG_SUCCESS, org };
}

function loadOrgFailure(errorStatus, errorMessage) {
  return { type: LOAD_ORG_FAILURE, errorMessage, errorStatus };
}

const UPDATE_ORG_FLAGS = 'USER/UPDATE_ORG_FLAGS';
function updateOrgFlags(flagsObject = {}) {
  return { type: UPDATE_ORG_FLAGS, flagsObject };
}

const LOAD_MULTI_APPS_LIST_SUCCESS = 'USER/LOAD_MULTI_APPS_LIST/SUCCESS';
const loadMultiAppsListSuccess = (multiAppsList) => ({
  type: LOAD_MULTI_APPS_LIST_SUCCESS,
  multiAppsList,
});

function userReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_USER: {
      return {
        ...state, loading: true,
      };
    }
    case LOAD_USER_SUCCESS: {
      const { user: _user = {} } = action;
      const { is_dev, is_staff, is_internaluser } = _user;

      const user = clone(_user);
      set(user, 'role', roleTranslation[_user.role]);

      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: '',
        user,
        loaded: true,
        staffUser: is_dev || is_staff || is_internaluser,
      };
    }
    case LOAD_USER_FAIL: {
      const { message } = action;
      return {
        ...state, loading: false, error: true, errorMessage: message,
      };
    }
    case LOAD_ORG_REQUEST: {
      const { organization } = state;
      return { ...state, organization: { ...organization, loading: true } };
    }
    case LOAD_ORG_SUCCESS: {
      const { organization, user } = state;
      const { org } = action;

      return {
        ...state,
        user: { ...user, organization: { ...user.organization, ...org } },
        organization: {
          ...organization,
          loading: false,
          error: false,
          loaded: true,
        },
      };
    }
    case LOAD_ORG_FAILURE: {
      const { organization } = state;
      return { ...state, organization: { ...organization, loading: false, error: true } };
    }

    case LOAD_MULTI_APPS_LIST_SUCCESS: {
      const { multiAppsList } = action;
      return {
        ...state,
        multiAppsList,
      };
    }

    case SET_IS_SETTINGS_IFRAME: {
      const { isSettingsIframe } = action;
      return {
        ...state,
        settingsConfig: {
          ...state.settingsConfig,
          isSettingsIframe,
        },
      };
    }

    case SET_IS_SETTINGS_MANAGE_ROUTE: {
      const { isSettingsManageRoute } = action;
      return {
        ...state,
        settingsConfig: {
          ...state.settingsConfig,
          isSettingsManageRoute,
        },
      };
    }
    case SET_IS_SETTINGS_ROUTE: {
      const { isSettingsRoute } = action;
      return {
        ...state,
        settingsConfig: {
          ...state.settingsConfig,
          isSettingsRoute,
        },
      };
    }
    case SET_ACTIVE_SETTINGS: {
      const { activeSettings } = action;
      return {
        ...state,
        settingsConfig: {
          ...state.settingsConfig,
          activeSettings,
        },
      };
    }
    case SET_EXPANDED_ITEM: {
      const { expandedItem } = action;
      if (state.settingsConfig.expandedItem === expandedItem) {
        return state;
      }
      return {
        ...state,
        settingsConfig: {
          ...state.settingsConfig,
          expandedItem,
        },
      };
    }
    case SET_CURRENT_APP: {
      const { currentApp } = action;
      if (state.settingsConfig.currentApp === currentApp) {
        return state;
      }
      return {
        ...state,
        settingsConfig: {
          ...state.settingsConfig,
          currentApp,
        },
      };
    }
    case SET_ACTIVE_ITEM: {
      const { activeItem } = action;
      if (state.settingsConfig.activeItem === activeItem) {
        return state;
      }
      return {
        ...state,
        settingsConfig: {
          ...state.settingsConfig,
          activeItem,
        },
      };
    }
    case SET_ACTIVE_SUB_ITEM: {
      const { activeSubItem } = action;
      if (state.settingsConfig.activeSubItem === activeSubItem) {
        return state;
      }
      return {
        ...state,
        settingsConfig: {
          ...state.settingsConfig,
          activeSubItem,
        },
      };
    }
    case SET_IS_EXPANDED_DRAWER: {
      const { isExpandedDrawer } = action;
      if (state.settingsConfig.isExpandedDrawer === isExpandedDrawer) {
        return state;
      }
      return {
        ...state,
        settingsConfig: {
          ...state.settingsConfig,
          isExpandedDrawer,
        },
      };
    }

    case SET_RESET_APP_SETTING_RANDOM: {
      const { resetAppSettingRandom } = action;
      return {
        ...state,
        settingsConfig: {
          ...state.settingsConfig,
          resetAppSettingRandom,
        },
      };
    }

    case SET_IFRAME_CONTAINER_URL: {
      const { iFrameContainerURL } = action;
      if (state.settingsConfig.iFrameContainerURL === iFrameContainerURL) {
        return state;
      }
      return {
        ...state,
        settingsConfig: {
          ...state.settingsConfig,
          iFrameContainerURL,
        },
      };
    }


    case UPDATE_ORG_FLAGS: {
      const { user } = state;
      const { flagsObject } = action;
      return {
        ...state,
        user: {
          ...user,
          organization: {
            ...user.organization,
            flags: {
              ...user.organization.flags,
              ...flagsObject,
            },
          },
        },
      };
    }
    default:
      return state;
  }
}

export const actions = {
  load,
  loadSuccess,
  loadFail,
  loadOrg,
  loadOrgFailure,
  loadOrgSuccess,
  updateOrgFlags,
  loadMultiAppsListSuccess,
  setIsSettingsIframe,
  setIsSettingsManageRoute,
  setIsSettingsRoute,
  setActiveSettings,
  setExpandedItem,
  setActiveItem,
  setActiveSubItem,
  setIsExpandedDrawer,
  setResetAppSettingRandom,
  setIFrameContainerURL,
  setCurrentApp,
};

export const actionTypes = {
  LOAD_USER,
  LOAD_USER_SUCCESS,
  LOAD_ORG_SUCCESS,
  LOAD_ORG_REQUEST,
};

export default userReducer;
