import React from 'react';
import { Link } from 'v2-components';
import { ADVERTISING_PATHS, AD_TYPE_IDS } from 'routes/Advertising/constants';
import PropTypes from 'prop-types';

export const DATE_LABELS = {
  current_month: 'Current Month',
  current_week: 'Current Week',
  last_1_days: 'Last 1 Day',
  last_7_days: 'Last 7 Days',
  last_30_days: 'Last 30 Days',
  last_90_days: 'Last 90 Days',
};

export const LINKEDIN_SEGMENT_ACCOUNT_COUNT_LIMIT=1000000;

export const INDUSTRIES_WITH_NO_SUB_INDUSTRIES = new Set([
  'Aerospace & Defense',
  'Associations & Non Profits',
  'Biotech & Pharmaceuticals',
  'Government',
  'Hardware & Semiconductors',
  'Information Technology',
  'Internet',
  'Real Estate, Rentals, and Leasing',
  'Software',
  'Telecommunications',
  'Wholesale & Distribution',
]);

export const INDUSTRY_V2_FILTER_LABELS = {};
for (const rootIndustry of INDUSTRIES_WITH_NO_SUB_INDUSTRIES) {
  INDUSTRY_V2_FILTER_LABELS[`${rootIndustry}: Other`] = rootIndustry;
}

export const sanitizedFieldNames = ['password', 'confirmPassword'];
// All of the paths to sanitize. (omit) from the store. if the path to this
// Changes this will break.
export const sanitizedFieldPaths = [
  'form.LOGIN_FROM.VALUES.password',
  'segmentAnalytics.analytics.analytics',
  'crossfilterRedux',
  'segmentAnalytics',
];
export const actionPrototypes = Object.freeze({
  isIgnored: '_isIgnored',
});


export const DOWNLOAD_ACCOUNTS_MAX = 100000;
export const GLOBAL_ERROR_MESSAGE = 'Something happened. Please refresh the page and try again.';
export const API_URL = 'cricketv2-aa.dev.6si.com';
export const VISITORS = 'Vistors';
export const ACCOUNTS = 'Accounts';
export const PAGE_VIEWS = 'Page Views';
export const PAGE_CLICKS = 'Page Clicks';
export const DATE_FORMAT = 'MMM D, YYYY';
export const operatorLabel = {
  starts_with: 'Starts With',
  '=': 'Equals',
  contains: 'Contains',
  not_contains: 'Does not contain',
};

export const PX_REM_SIZE_FACTOR = 14;

export const RELEASE_TYPES = {
  ALPHA: 'ALPHA',
  BETA: 'BETA',
  EARLY_ACCESS: 'EARLY_ACCESS',
};

export const RELEASE_TYPE_LOGOS = {
  [RELEASE_TYPES.ALPHA]: 'alpha_release_logo.svg',
  [RELEASE_TYPES.BETA]: 'beta_release_logo.svg',
  [RELEASE_TYPES.EARLY_ACCESS]: 'early_access.svg',
};

// Section Headers
const GENERAL_FORM = (
  <span>
    All required fields must be filled in to submit this campaign.
    <br />
    Only a campaign name is required to save as a draft. Once the campaign is submitted, you must
    first deactivate the campaign to make any changes.
  </span>
);

// Instruction text
const CREATE_SEGMENT_INSTRUCTION = <span>*Pick a segment</span>;

const JOB_TARGET_INSTRUCTION = (
  <span>
    Refine your campaign by selecting one or more Job Level and Job Function combinations
  </span>
);

const JOB_TARGET_FEE = (
  <span>
    (Important Note: An additional fee of $4.00 CPM will apply when adding one or more combinations)
  </span>
);

const BETA_JF_NOTE = (
  <span>
    job functions don't guarantee reach. We recommend adding more job functions
  </span>
);

const SEGMENT_NOTE_TEXT = (
  <span>
    (Note: This will restrict the audience to the selected segment accounts)
  </span>
);

const DELETE_WARNING_MESSAGE = (
  <div>
    <p>
      Are you sure you want to <b>delete</b> this campaign?
    </p>
  </div>
);

const DELETE_SEGMENT_WARNING_MESSAGE = (
  <div>
    <p>
      Are you sure you want to <b>delete</b> this segment?
    </p>
  </div>
);

const DEACTIVATE_WARNING_MESSAGE = (
  <div>
    <p>
      Are you sure you want to <b>deactivate</b> this campaign?
    </p>
  </div>
);

const ACTIVATE_WARNING_MESSAGE = (
  <div>
    <p>
      Are you sure you want to <b>activate</b> this campaign?
    </p>
  </div>
);

const END_WARNING_MESSAGE = (
  <div>
    <p>
      Are you sure you want to <b>end</b> this campaign?
    </p>
  </div>
);

const AD_PROVIDER_INFO = (
  <span>
    Note: Please contact <a href="mailto:support@6sense.com">
      support@6sense.com</a> if your provider is NOT in the list.
      For more details, please review&nbsp;
    <a
      href="https://support.6sense.com/hc/en-us/articles/360037629353"
      target="_blank"
      rel="noopener noreferrer"
    >External Media Campaigns FAQ's</a>
  </span>
);

const MACROS_PROVIDER_INFO = (
  <span>
    Note: Select "Tracking with static Ad URL(s)" option if your provider is NOT in the list.
    For more details, please review&nbsp;
    <a
      href="https://support.6sense.com/hc/en-us/articles/360037629353"
      target="_blank"
      rel="noopener noreferrer"
    >External Media Campaigns FAQ's</a>
  </span>
);

// HTML Allert Strings
const CAMPAIGN_ZERO_DATA = (
  <div>
    <p>
      No campaigns found.
      <br />
      Try searching with a different criteria.
    </p>
  </div>
);

const CAMPAIGN_STATS_ZERO_DATA = (
  <div>
    <p>No accounts have been reached by this campaign.</p>
  </div>
);

const SEGMENT_ZERO_DATA = (
  <div>
    <p>
      No segments found.
      <br />
      Try searching with a different criteria.
    </p>
  </div>
);

const CARD_NO_DATA = (
  <div>
    <p>
      No data is available yet in the system.
      <br />
      The system is in the process of being set up.
      <br />
      Please try again later.
    </p>
  </div>
);

const CARD_ZERO_DATA_DASHBOARD = (
  <div>
    <p>
      No data is available.
      <br />
      Please select a different search criteria or try again later.
    </p>
  </div>
);

const CARD_ZERO_DATA_GENERIC = (
  <div>
    <p>No data is available.</p>
  </div>
);

const CARD_ERROR = (
  <div>
    <p>
      No data can be loaded.
      <br /> Please refresh the page and try again.
    </p>
  </div>
);

const CAMPAIGN_ERROR = (
  <div>
    <p>
      No campaigns can be loaded.
      <br />
      Please refresh the page and try again.
    </p>
  </div>
);

const SEGMENT_ERROR = (
  <div>
    <p>
      No segments can be loaded.
      <br />
      Please refresh the page and try again.
    </p>
  </div>
);

const PAGE_LEVEL_ERROR = (
  <div>
    <p>
      Something went wrong.
      <br />
      Please refresh the page and try again.
    </p>
  </div>
);

const NOT_FOUND = (
  <div>
    <p>
      This page does not exist.
      <br />
      At least not in big deep data space.
      <br />
      (404 Error)
    </p>
  </div>
);

const TOP_LEVEL_ERROR = (
  <div>
    <p>
      Something went wrong in
      <br />
      deep big data space
    </p>
  </div>
);

const PASSWORD_RULES = (
  <ul>
    <li>Must be at least 12 characters</li>
    <li>Must contain at least 1 alphabetic character</li>
    <li>Must contain at least 1 uppercase character</li>
    <li>Must contain at least 1 number</li>
    <li>Must contain at least 1 special character</li>
  </ul>
);

// LINKEDIN INTEGRATION
const LINKEDIN_SUCCESS_INFO = (
  <span>
    LinkedIn integration is active now, Segments are ready to sync.
  </span>
);

const LINKEDIN_FAILURE_INFO = (
  <span>
    Something went wrong, Please try again later.
  </span>
);

const TOO_MANY_ACCOUNTS = (
  <span>
    The segment cannot have more than <b>100K accounts</b>. Please modify your segment definition
    or pick a different segment to reduce the targeted list of accounts below 100K.
  </span>);

const PROVIDER_WARNING_COVID = (
  <span>
    Due to COVID-19, Google Support services are temporarily unavailable or delayed.
    As such, Google's support team may take longer than expected to implement 6sense
    impression trackers in Google Adwords or provide any investigation support for our customers.
    If your campaign experiences any tracking issues, we recommend reaching out directly to
    your active Point of Contact within Google Support to facilitate a solution.&nbsp;
    <br />
    <a
      target="_blank"
      rel="noopener noreferrer"
      href="https://support.google.com/google-ads/topic/9803759?hl=en&ref_topic=3181080,3126923"
    >
      Google's Coronavirus (COVID-19) Resource and Updates
  </a>
  </span>
);
const PASSWORD_RULES_HEADER = <span>Password Requirements</span>;

export const DYNAMIC_AD_ERROR = (<span>Dynamic ad should use Company Detail API.
 {/* eslint-disable-next-line max-len */}
 For more information <Link newWindow link={`/${ADVERTISING_PATHS.ad}/dynamic`}>click here</Link></span>);

// Validation strings
/*  eslint no-template-curly-in-string: 0 */
const MIN_DATE_SPAN = 'Less than allowed minimum flight of {min} {unit}.';
const REQUIRED_FIELD = 'Required Field';
const MAX_DATE_SPAN = 'Exceeds maximum flight of {max} {unit}.';
const MAX_LENGTH = 'Must be {max} characters or less.';
const IS_NUMBER = 'Must be a number';
const IS_CURRENCY = 'Invalid currency';
const INVALID_EMAIL = 'Invalid email address';
const MIN_LENGTH = 'Must be {min} characters or more';
const MIN_AMOUNT = 'Amount does not meet minimum: ${min}';
const GREATER_THAN = 'Amount entered is smaller than {gtDisplay}.';
const LESS_THAN = 'Amount entered is larger than ${ltDisplay}.';
const ALPHANUMERICAL = 'Syntax is not allowed. Name must be alphanumerical and may contain spaces.';
const SUBDOMAIN_CONFIG = `Your subdomain may only include letters, numbers, and hyphens.
Please review the full URL structure displayed here to ensure
you have selected the appropriate Domain Type.`;
const CLICK_URL = 'Invalid URL';
const NAME_EXISTS = 'Name already exists.';
const ICON_FILE_EXPECT_RATIO = `Selected image ratio does not match
desired image aspect ratio of 1x1`;
const ICON_MAX_WIDTH = 'Selected image width does not match desired minimum image width of 300px';
const IMAGE_MAXMIN_DIMENSIONS = `Selected image size {value} does not match
desired minimum image size of {desiredSize}`;
const FILE_SIZE = 'Selected image size {value} does not match actual image size {actual_size}';
const VIDEO_SIZE = 'Selected video size {value} does not match actual video size {actual_size}';
const FILE_TYPE = 'File type {type} not allowed';
const FILE_LARGE = 'Attached file of {size} KB larger than maximum supported file size of 150 KB';
const VIDEO_FILE_LARGE = `Attached file of {size} KB larger than maximum
supported file size of 125 MB`;
const MAX_VALUE_TEXT_CONTD = ' You may want to verify this is the intended amount.';
const MAX_VALUE = 'We noticed you entered in a campaign budget over ${max}.' + MAX_VALUE_TEXT_CONTD; // eslint-disable-line
const PASSWORD_INCONSISTENT = 'Passwords do not match.';
const EMAIL_REQUIRED = 'Please enter a valid email';
const PASSWORD_REQUIRED = 'Please enter your password';
const NO_DUPLICATES = 'Please remove duplicates';
const ALL_OR_NOTHING = 'Every Job Function must be accompanied by a Job Level and vice versa';
const MIN_NUMBER = 'Must be {min} or more';
const MAX_NUMBER = 'Must be {max} or fewer';
const MIN_API_LIMIT = 'Please enter a value of at least {min}';
// Alert strings
const LEAVE_CREATE_CAMPAIGN = 'You will lose all unsaved changes.';
const CAMPAIGNS_NO_DATA = 'No campaigns have been created yet.';
const CREATE_CANCEL_CONTENT = 'Are you sure you want to cancel? You will lose all unsaved changes.';
const CREATE_CANCEL_OK = 'Yes, Leave Page';
const CREATE_ERROR_ALL_SECTIONS = `All required sections and fields must be complete before this
campaign may be submitted. Please complete all required parts and try again.`;
const CREATE_ERROR_400 = `Errors must be properly fixed before this campaign may be saved.
  Please correct any errors and try again.`;
const ERROR_500 = 'Something went wrong. Please try again.';
const DISABLE_ADBLOCKER = `Please disable your ad blocker and
refresh the page to see this ad content.`;

const CREATE_PUBLISH_CONTENT = `Are you sure you want to submit this campaign?
You will no longer be able to modify which segment this campaign is using nor the segment itself.`;
const CREATE_PUBLISH_OK = 'Yes, Submit Campaign';
const CREATE_STATE_ACTION_OK = 'Yes, {action} Campaign';
const LIST_CAMPAIGN_ACTION_MESSAGE_SUCCESS = 'Campaign successfully updated.';
const LIST_CAMPAIGN_ACTION_DESCRIPTION_SUCCESS = 'Campaign was {stateAction}d';
const LIST_CAMPAIGN_ACTION_MESSAGE_FAILURE = 'Campaign could not be updated';
const CREATE_CAMPAIGN_SAVE_MESSAGE_SUCCESS = 'Campaign has been successfully saved.';
const CREATE_CAMPAIGN_SAVE_DESCRIPTION_SUCCESS =
  'Campaign must still be submitted for changes to take effect.';
const CREATE_CAMPAIGN_PUBLISH_MESSAGE_SUCCESS =
  'The campaign has been successfully submitted and will be activated if passes review.';
const CREATE_CAMPAIGN_PLACEMENT_GROUP =
  'At least one placement group and one placement for that group is required to submit a campaign';
const PLACEMENT_GROUP_NO_PLACEMENT =
  'At least one placement for each placement group is required to submit a campaign';
const PLACEMENT_UPLOAD_HINT =
  'Must upload one creative for this placement. Accepted file formats: ' +
  'JPG, PNG. Maximum accepted file size: 150 KB';

const PLACEMENT_NATIVEIMAGE_UPLOAD_HINT =
  'Must upload one creative for this placement. Accepted file formats: ' +
  'JPG, PNG. Minimum Size: 1200x627px';

const PLACEMENT_VIDEO_UPLOAD_HINT =
  'Must upload one creative for this placement.Max Video File Size: 125MB.' +
  'Minimum Size: 1280x720px. Minimum Bit Rate: 2500kbps';

const PLACEMENT_NATIVE_UPLOAD_HINT =
  'Must upload one creative for this placement.Maximum accepted file size: 150 KB.' +
  'Minimum aspect ratio: 1*1';

const PLACEMENT_HTML5_UPLOAD_HINT =
  'Must upload one creative for this placement. Accepted file formats: .zip' +
  ' Must contain an index.html file';
const PLACEMENT_HTML5_UPLOAD_EXTRA_HINT =
  'To ensure that your creative is set up to click correctly, read the prerequisites';
const PLACEMENT_HTML5_ERROR = 'Invalid Zip file. Must contain an index.html file';
const PLACEMENT_UPLOAD_DISABLED =
  'Only one creative per placement allowed. To add a different creative, remove current one.';
const LEAVE_KEYWORDS_CONFIG = 'All unsaved keywords will be lost';
const LEAVE_CREATE_AD_PLACEMENT = 'All unsaved data will be lost';

export const CREATIVE_NAME_ERROR = 'Please avoid using wildcards (eg. !@#$%^&*+?~\') in';

export const CONTAIN_HTML_ZIP_ERROR =
`Nested zip files are not supported.
Please ensure that the HTML creative file does not contain any nested zip files.`;

export const CONTAIN_DYNAMIC_HTML_ZIP_ERROR =
`Nested zip files are not supported.
Please ensure that the dynamic HTML creative file does not contain any nested zip files.`;

export const CREATIVE_TYPE_ERROR = 'You have attempted to upload an unsupported file type.';

// Ad Placements
const AD_PLACEMENT_DELETE_SUCCESS = 'Ad successfully deleted';
const AD_PLACEMENT_DELETE_FAILURE = 'Ad could not be deleted';

const AD_PLACEMENT_CLONE_SUCCESS = 'Ad successfully cloned';
const AD_PLACEMENT_CLONE_FAILURE = 'Ad could not be cloned';

const AD_PLACEMENT_SUBMIT_SUCCESS = 'Ad successfully created';
const AD_PLACEMENT_SUBMIT_FAILURE = 'Ad could not be saved';
const AD_PLACEMENT_UPDATE_SUCCESS = 'Ad successfully updated';

// Hint strings
const CAMPAIGN_FILIGHT_HINT = 'Minimum flight is 1 week.';
const CAMPAIGN_BUDGET_HINT = 'Minimum Budget: $100.00';
const CLICK_URL_HINT =
  'Shortened URLs like those from bit.ly and others cannot be used to setup click URLs.'; // eslint-disable-line

// Segment strings
const SEGMENT_MANAGE_ERROR = `Something went wrong and we were not
able to perform this action. Please refresh the page and try again.`;
const SEGMENT_MODAL_ERROR = 'Something went wrong. Please try again.';
const SEGMENT_CANNOT_EDIT = `This segment is associated to one or more campaigns
no longer in draft status so cannot be edited.`;
const SEGMENT_CANNOT_DELETE =
  'This segment is being used by one or more campaigns so cannot be deleted.';
const SEGMENT_CANNOT_DELETE_DEPENDENT =
  'This segment is being used by one or more segments so cannot be deleted.';
const SEGMENT_CANNOT_SYNC = 'This segment cannot be synced.';

// Segment Account List
const SEGMENT_DOWNLOAD_ERROR = `Something went wrong and we were not
able to perform the download action. Please refresh the page
and try again.`;

const SEGMENT_DOWNLOAD_MAX = 'Only lists under 25,000 accounts may be downloaded.';

// Segment Upload
const SEGMENT_TECHNOGRAPH_HACK =
  'Temporarily archived and cannot edit due to ' +
  'technographic data updates. Contact your 6sense CSM to learn more.';
const SEGMENT_CSV_UPLOAD = 'Must upload one csv file.';
const SEGMENT_CSV_DISABLED = 'Only one csv file allowed. To add a file, remove the current one.';
const SEGMENT_CSV_WRONG_FILE = 'Please upload a proper .csv file.';
const SEGMENT_CSV_BAD_URL = 'The following is not a proper website: ';
const SEGMENT_CSV_NEED_RECORDS = 'You must have at least one valid company record.';
const SEGMENT_CSV_UPLOAD_ERROR = 'There was a problem uploading the file. Please try again.';
const SEGMENT_ACCOUNT_STATS_ERROR = 'There was a problem analyzing the file. Please try again.';
const SEGMENT_UPLOADED_TOOLTIP = 'Account rows from your uploaded file';
const SEGMENT_MATCHED_TOOLTIP =
  'Accounts that 6sense algorithms were able to match to those in our database';
const SEGMENT_DUPLICATE_TOOLTIP =
  'Accounts where the 6sense algorithm ' +
  'already matched another record in your uploaded file to the same account ' +
  'in our database';
const SEGMENT_UNMATCHED_TOOLTIP =
  'Accounts where the 6sense algorithm ' +
  'was not able to match due to incomplete, insufficient, or invalid data in ' +
  'your file, or we could not find a matching account in our 6sense database';

// PRELOGIN Errors and messages
const PRELOGIN_GENERIC_ERROR =
  'Something went wrong and we were not able to' +
  ' perform this action. Please refresh the page and try again.';
const PRELOGIN_FAILED_ORG_ERR =
  'We could not find your organization. Please check the URL and try again.';
const PRELOGIN_PASSWORD_SET_SUCCESS = 'Your password has been successfully reset.';
const ORG_NAME_REQUIRED = 'Please enter your organization name';
const AUTH_TIMED_OUT = 'Your session has timed out';

// Blacklist
const UPDATED_BLACKLIST =
  'Changes will be applied within 24 hours to data moving forward';
const BLACKLIST_SAVE_PROMPT = 'Are you sure you want to remove this rule?';
const BLACKLIST_OK_TEXT = 'Yes';
const BLACKLIST_SAVE_ERROR =
  'Something went wrong and we were not able to save blacklist. ' +
  'Please refresh the page and try again';
const BLACKLIST_LOAD_ERROR = 'Could not load blacklist';

// CAMPAIGN CARD
const ACCOUNTS_REACHED_TOOLTIP = 'Accounts Reached';
const NEW_ACCOUNTS_TOOLTIP = 'Newly engaged accounts';
const INCREASED_ENGAGEMENT_TOOLTIP = 'Increased engagement';
const AVG_INCREASE_TOOLTIP = 'Average increase per account';
const ACCOUNT_VTR_TOOLTIP = 'Account VTR';
const ACCOUNT_CTR_TOOLTIP = 'account CTR';

const SET_LINKEDIN_DEFAULT_AD_ACCOUNT_SUCCESS = 'LinkedIn Default Ad Account updated successfully';
const SET_LINKEDIN_DEFAULT_AD_ACCOUNT_FAILURE =
  'Something Went wrong, Cannot update Default Ad Account';

const STOP_LINKEDIN_SYNC_TEXT = 'The segment will persist and can be used in campaigns ' +
  'but will not receive any updates from 6sense';

const CANNOT_ACTIVATE_POST_END_DATE =
  'Campaign cannot be reactivated as it is past its flight end date';

// Lookalikes
const LOOKALIKE = 'Lookalike';

// Status Codes
const HTTP_200_OK = 200;
const HTTP_204_NO_CONTENT = 204;
const HTTP_300_MULTIPLE_CHOICES = 300;
const HTTP_400_BAD_REQUEST = 400;
const HTTP_401_UNAUTHORIZED = 401;
const HTTP_401_MOD_UNAUTHORIZED = 40101;
const HTTP_403_FORBIDDEN = 403;
const HTTP_404_NOT_FOUND = 404;
const HTTP_404_MOD_NOT_FOUND = 40401;
const HTTP_422_UNKNOWN = 422;
const HTTP_429_TOO_MANY_REQUESTS = 429;
const HTTP_500_INTERNAL_SERVER_ERROR = 500;
const HTTP_503_SERVICE_UNAVAILABLE = 503;

const AUTOCOMPLETE = 'matching';
const SELECTION = 'selection';
const GROUPED_SELECTION = 'grouped_selection';
const NUMERIC = 'numeric';
const INPUT = 'input';
const FETCHABLE = 'fetchable';
const DATE = 'date';
const BOOLEAN = 'boolean';

// Audience
const AUDIENCE_SUBMIT_SUCCESS = 'Audience successfully created';
const AUDIENCE_SUBMIT_FAILURE = 'Audience could not be saved';
const AUDIENCE_UPDATE_SUCCESS = 'Audience successfully updated';

const REALTIME_SCORING_PLATFORM = ['Eloqua', 'Hubspot', 'Pardot'];
const HIDE_SITEID_FEATURE = ['Pardot'];

const SUBDOMAIN_EXCEPTIONS = ['.edu', '.gov', '.mil', '.sh'];

export const filterFieldTypes = {
  AUTOCOMPLETE,
  SELECTION,
  NUMERIC,
  INPUT,
  FETCHABLE,
  DATE,
  BOOLEAN,
  GROUPED_SELECTION,
};

export const codes = {
  HTTP_404_NOT_FOUND,
  HTTP_404_MOD_NOT_FOUND,
  HTTP_503_SERVICE_UNAVAILABLE,
  HTTP_401_MOD_UNAUTHORIZED,
  HTTP_401_UNAUTHORIZED,
  HTTP_403_FORBIDDEN,
  HTTP_300_MULTIPLE_CHOICES,
  HTTP_200_OK,
  HTTP_204_NO_CONTENT,
  HTTP_500_INTERNAL_SERVER_ERROR,
  HTTP_400_BAD_REQUEST,
  HTTP_422_UNKNOWN,
  HTTP_429_TOO_MANY_REQUESTS,
};

export const strings = {
  BLACKLIST_OK_TEXT,
  BLACKLIST_SAVE_PROMPT,
  ACCOUNTS_REACHED_TOOLTIP,
  NEW_ACCOUNTS_TOOLTIP,
  INCREASED_ENGAGEMENT_TOOLTIP,
  AVG_INCREASE_TOOLTIP,
  ACCOUNT_VTR_TOOLTIP,
  ACCOUNT_CTR_TOOLTIP,
  CLICK_URL_HINT,
  CAMPAIGNS_NO_DATA,
  MIN_DATE_SPAN,
  REQUIRED_FIELD,
  MAX_DATE_SPAN,
  MAX_LENGTH,
  IS_NUMBER,
  IS_CURRENCY,
  INVALID_EMAIL,
  MIN_LENGTH,
  MIN_AMOUNT,
  GREATER_THAN,
  LESS_THAN,
  ALPHANUMERICAL,
  SUBDOMAIN_CONFIG,
  CLICK_URL,
  NAME_EXISTS,
  ICON_FILE_EXPECT_RATIO,
  ICON_MAX_WIDTH,
  IMAGE_MAXMIN_DIMENSIONS,
  FILE_SIZE,
  VIDEO_SIZE,
  FILE_TYPE,
  FILE_LARGE,
  VIDEO_FILE_LARGE,
  MAX_VALUE,
  CAMPAIGN_FILIGHT_HINT,
  CREATE_CANCEL_CONTENT,
  CREATE_CANCEL_OK,
  CAMPAIGN_BUDGET_HINT,
  CREATE_STATE_ACTION_OK,
  CREATE_PUBLISH_OK,
  CREATE_PUBLISH_CONTENT,
  ERROR_500,
  CREATE_ERROR_400,
  CREATE_ERROR_ALL_SECTIONS,
  LIST_CAMPAIGN_ACTION_MESSAGE_SUCCESS,
  LIST_CAMPAIGN_ACTION_DESCRIPTION_SUCCESS,
  LIST_CAMPAIGN_ACTION_MESSAGE_FAILURE,
  CREATE_CAMPAIGN_SAVE_MESSAGE_SUCCESS,
  CREATE_CAMPAIGN_SAVE_DESCRIPTION_SUCCESS,
  CREATE_CAMPAIGN_PUBLISH_MESSAGE_SUCCESS,
  CREATE_CAMPAIGN_PLACEMENT_GROUP,
  PLACEMENT_GROUP_NO_PLACEMENT,
  PLACEMENT_UPLOAD_HINT,
  PLACEMENT_VIDEO_UPLOAD_HINT,
  PLACEMENT_NATIVE_UPLOAD_HINT,
  PLACEMENT_HTML5_UPLOAD_HINT,
  PLACEMENT_HTML5_UPLOAD_EXTRA_HINT,
  PLACEMENT_HTML5_ERROR,
  PLACEMENT_NATIVEIMAGE_UPLOAD_HINT,
  PLACEMENT_UPLOAD_DISABLED,
  PASSWORD_INCONSISTENT,
  SEGMENT_MANAGE_ERROR,
  SEGMENT_MODAL_ERROR,
  SEGMENT_CANNOT_EDIT,
  SEGMENT_CANNOT_DELETE,
  SEGMENT_CANNOT_DELETE_DEPENDENT,
  SEGMENT_CANNOT_SYNC,
  SEGMENT_CSV_UPLOAD,
  SEGMENT_CSV_DISABLED,
  SEGMENT_CSV_WRONG_FILE,
  SEGMENT_CSV_BAD_URL,
  SEGMENT_CSV_NEED_RECORDS,
  SEGMENT_CSV_UPLOAD_ERROR,
  SEGMENT_ACCOUNT_STATS_ERROR,
  SEGMENT_UPLOADED_TOOLTIP,
  SEGMENT_MATCHED_TOOLTIP,
  SEGMENT_DUPLICATE_TOOLTIP,
  SEGMENT_UNMATCHED_TOOLTIP,
  SEGMENT_TECHNOGRAPH_HACK,
  EMAIL_REQUIRED,
  PASSWORD_REQUIRED,
  PRELOGIN_GENERIC_ERROR,
  PRELOGIN_PASSWORD_SET_SUCCESS,
  ORG_NAME_REQUIRED,
  PRELOGIN_FAILED_ORG_ERR,
  AUTH_TIMED_OUT,
  LEAVE_CREATE_CAMPAIGN,
  LEAVE_KEYWORDS_CONFIG,
  UPDATED_BLACKLIST,
  SEGMENT_DOWNLOAD_ERROR,
  SEGMENT_DOWNLOAD_MAX,
  NO_DUPLICATES,
  ALL_OR_NOTHING,
  BLACKLIST_SAVE_ERROR,
  BLACKLIST_LOAD_ERROR,
  LOOKALIKE,
  MIN_NUMBER,
  MIN_API_LIMIT,
  MAX_NUMBER,
  LEAVE_CREATE_AD_PLACEMENT,
  AD_PLACEMENT_DELETE_FAILURE,
  AD_PLACEMENT_DELETE_SUCCESS,
  AD_PLACEMENT_CLONE_FAILURE,
  AD_PLACEMENT_CLONE_SUCCESS,
  AD_PLACEMENT_SUBMIT_SUCCESS,
  AD_PLACEMENT_SUBMIT_FAILURE,
  AD_PLACEMENT_UPDATE_SUCCESS,
  AUDIENCE_SUBMIT_SUCCESS,
  AUDIENCE_SUBMIT_FAILURE,
  AUDIENCE_UPDATE_SUCCESS,
  DISABLE_ADBLOCKER,
  REALTIME_SCORING_PLATFORM,
  HIDE_SITEID_FEATURE,
  SET_LINKEDIN_DEFAULT_AD_ACCOUNT_SUCCESS,
  SET_LINKEDIN_DEFAULT_AD_ACCOUNT_FAILURE,
  STOP_LINKEDIN_SYNC_TEXT,
  CANNOT_ACTIVATE_POST_END_DATE,
  SUBDOMAIN_EXCEPTIONS,
};

export const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i; // eslint-disable-line

export const htmlStrings = {
  GENERAL_FORM,
  JOB_TARGET_INSTRUCTION,
  JOB_TARGET_FEE,
  BETA_JF_NOTE,
  CREATE_SEGMENT_INSTRUCTION,
  DELETE_WARNING_MESSAGE,
  DEACTIVATE_WARNING_MESSAGE,
  ACTIVATE_WARNING_MESSAGE,
  END_WARNING_MESSAGE,
  CAMPAIGN_ZERO_DATA,
  CAMPAIGN_STATS_ZERO_DATA,
  SEGMENT_ZERO_DATA,
  CARD_NO_DATA,
  CARD_ERROR,
  CAMPAIGN_ERROR,
  SEGMENT_ERROR,
  NOT_FOUND,
  TOP_LEVEL_ERROR,
  DELETE_SEGMENT_WARNING_MESSAGE,
  CARD_ZERO_DATA_DASHBOARD,
  CARD_ZERO_DATA_GENERIC,
  PASSWORD_RULES,
  PASSWORD_RULES_HEADER,
  PAGE_LEVEL_ERROR,
  AD_PROVIDER_INFO,
  MACROS_PROVIDER_INFO,
  SEGMENT_NOTE_TEXT,
  LINKEDIN_SUCCESS_INFO,
  LINKEDIN_FAILURE_INFO,
  TOO_MANY_ACCOUNTS,
  PROVIDER_WARNING_COVID,
};

export const INVALID_CHARS =
  ['+', '-', '=', '@', '!', '#', '$', '%', '^', '&', '*', '/', '~', '?', '<', '>'];

export const API_REQUEST_DATE_FORMAT = 'YYYY-MM-DD';

export const STATUS_STATE = {
  DEFAULT: { loading: false, loaded: false, error: false },
  REQ: { loading: true, loaded: false, error: false },
  SUC: { loading: false, loaded: true, error: false },
  FAIL: { loading: false, loaded: true, error: true },
};

export const CAMPAIGN_SOURCE = {
  EXTERNAL: 'external',
  INTERNAL: 'sixsense',
  RETARGETING: 'sixsense_retargeting',
  LINKEDIN: 'linkedin',
  LINKEDIN_ADVERTISING: 'linkedin_ads',
  CONTEXTUAL: 'sixsense_contextual',
};

export const CAMPAIGN_TYPES_WITH_100K_ACCOUNT_LIMIT = [
  CAMPAIGN_SOURCE.INTERNAL,
  CAMPAIGN_SOURCE.CONTEXTUAL,
  CAMPAIGN_SOURCE.LINKEDIN_ADVERTISING,
];

export const CAMPAIGN_SOURCE_LABELS = {
  [CAMPAIGN_SOURCE.EXTERNAL]: 'External Media',
  [CAMPAIGN_SOURCE.INTERNAL]: '6sense Advertising',
  [CAMPAIGN_SOURCE.RETARGETING]: '6sense Visitor Retargeting',
  [CAMPAIGN_SOURCE.LINKEDIN]: '6sense Segments for LinkedIn',
  [CAMPAIGN_SOURCE.LINKEDIN_ADVERTISING]: 'LinkedIn Advertising',
  [CAMPAIGN_SOURCE.CONTEXTUAL]: '6sense Contextual Targeting',
};

export const DEFAULT_CREATE_CAMPAIGN_TYPE = CAMPAIGN_SOURCE.INTERNAL;

export const OTHER_VALIDATIONS = {
  video: 'We support in-stream (VAST) video on secure (HTTPS) inventory',
  native: 'Should have 1.91:1 aspect ratio',
  icon: 'Should have 1:1 aspect ratio',
  html5: 'Must contain index.html file',
  html5_dynamic: 'Must contain index.html file',
};

export const ICON_DIM = ['300x300', '>300x300'];

export const ICON_FILE_SIZE = {
  maxSize: 150,
  minSize: 1,
};

export const ASPECT_RATIO = {
  video: '16:9',
  native: '1.91:1',
  icon: '1:1',
  [AD_TYPE_IDS.linkedInVideoAd]: '16:9, 1:1, 9:16, 4:5',
};

export const VALIDATE_DYNAMIC_AD_STRINGS = [
  'https://epsilon.6sense.com/v1/company/details',
  'https://epsilon.6sense.com/v3/company/details',
];

export const VIDEO_DURATION = [15, 30];
export const CREATIVE_LIMIT = 5;

export const NO_ABORT = 'noAbort';

export const MULTIPLE_DOMAIN_ERROR = 'To add multiple domains, please use bulk CSV upload option.';

export const SPONSORED_CONTENT_POSTER_STATUS = {
  APPROVED: 'APPROVED',
  REQUESTED: 'REQUESTED',
  REJECTED: 'REJECTED',
  REVOKED: 'REVOKED',
  UNKNOWN: 'UNKNOWN',
};

export const LINKEDIN_PAGE_STATUS = {
  IN_PROGRESS: 'IN_PROGRESS',
  APPROVED: 'SUCCESS',
  NOT_APPROVED: 'FAILED',
  DISABLED: 'DISABLED',
  UNKNOWN: 'UNKNOWN',
};

export const LINKEDIN_ADVERTISING_TOOLTIPS = {
  [SPONSORED_CONTENT_POSTER_STATUS.REJECTED]: 'Content poster access has been rejected',
  [SPONSORED_CONTENT_POSTER_STATUS.REVOKED]: 'Content poster access has been revoked',
  [LINKEDIN_PAGE_STATUS.DISABLED]: 'Linkedin page has been disabled',
};

export const LINKEDIN_PAGE_PROP_TYPE = PropTypes.shape({
  id: PropTypes.number,
  sponsored_content_poster_status: PropTypes.oneOf(Object.values(SPONSORED_CONTENT_POSTER_STATUS)),
  page_name: PropTypes.string,
  linkedin_id: PropTypes.string,
  is_ad_account_ready: PropTypes.bool,
  associated_campaign_count: PropTypes.number,
  is_deleted: PropTypes.bool,
  page_url: PropTypes.string,
  is_disabled: PropTypes.bool,
});

export const LINKEDIN_ADVERTISING_STATE_KEY = 'linkedin_advertising';
export const LINKEDIN_ADVERTISING_ROUTE = 'linkedin-advertising';
export const INTEGRATIONS_ROUTE = 'settings/integration';
export const INTEGRATIONS_SETUP_ROUTE = 'setup';
// TODO: Need to think through if we need a setup route, just like other integrations
export const LINKEDIN_ADS_INTEGRATION_SETUP_ROUTE =
  `${INTEGRATIONS_ROUTE}/${LINKEDIN_ADVERTISING_ROUTE}`;
export const ADVERTISING_LIST_ROUTE = 'advertising/campaigns/list';
export const LINKEDIN_POSTER_ACCESS_REVOKED = 'Content poster access revoked';
export const LINKEDIN_POSTER_ACCESS_REJECTED = 'Content poster access rejected';
export const RETARGETING_CAMPAIGN_WARNING = {
  INFO: `As of April 1st, 2023, we updated our visitor retargeting feature.
  If you'd like to use visitor retargeting, please ensure your installation
  of the 6sense JavaScript webtag is up to date.`,
  // eslint-disable-next-line max-len
  LEARN_MORE_URL: 'https://support.6sense.com/knowledge-base/13656220724115-using-6sense-for-visitor-retargeting-instructions/',
};

export const LINKEDIN_VIDEO_ASSET_ATTACHMENT_TYPE = {
  THUMBNAIL: 'linkedin_video_thumbnail',
  CAPTION: 'linkedin_video_caption',
};

export const LINKEDIN_VIDEO_ASSET_ATTACHMENT_MAP = {
  [LINKEDIN_VIDEO_ASSET_ATTACHMENT_TYPE.THUMBNAIL]: 'thumbnail',
  [LINKEDIN_VIDEO_ASSET_ATTACHMENT_TYPE.CAPTION]: 'caption',
};
