import { AUDIENCE_IFRAME_ID, WORKFLOWSUI_ENDPOINT } from './constants';
import * as qs from 'qs';
import { browserHistory } from 'react-router';
import { getAppUrlWithOrgName } from 'routes/Settings/routes/Manage/utils';


// ABM -> child (workflows)
export const emitMultiAppEventToChild = (eventData) => { // ABM To child window
  const iframeSettingsElement = document.getElementById(AUDIENCE_IFRAME_ID);
  const wfUIEndpointWithOrgName = getAppUrlWithOrgName(WORKFLOWSUI_ENDPOINT);

  if (iframeSettingsElement) {
    iframeSettingsElement.contentWindow.postMessage(eventData, wfUIEndpointWithOrgName);
  }
};

export const setActiveSearchParam = (params) => {
  const nextActivePath = params.activePath;
  // Parse the existing query parameters
  const prevSearchParams = qs.parse(
    browserHistory.getCurrentLocation().search,
    { ignoreQueryPrefix: true }
  );
  const prevActivePath = prevSearchParams.activePath;

  if (prevActivePath !== nextActivePath) {
    // Set the new query parameter
    const nextParamsObject = {
      ...prevSearchParams,
      ...params,
    };

    const nextSearchParams = qs.stringify(nextParamsObject, { addQueryPrefix: true });

    browserHistory.replace({
      pathname: browserHistory.getCurrentLocation().pathname,
      search: nextSearchParams,
    });
  }
};

export const getCurrentSearchParamValue = (paramName) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  return urlParams.get(paramName);
};

export const trimStartingSlash = (inputString) => {
  if (inputString.startsWith('/')) {
    return inputString.slice(1);
  }
  return inputString;
};
