import React from 'react';
import PropTypes from 'prop-types';
import { LayoutCard } from 'HOCS';
import { KeyWord, Row, Col } from 'v2-components';
import styles from './KeywordCard.module.scss';
import { coerceLocaleString } from 'utils/utils';
import { compose } from 'redux';

function KeywordCardComponent(props) {
  const {
    dataset,
    action,
    dataKey,
    toolTip,
  } = props;

  const keywords = dataset;

  return (
    <div className={styles.pillChartOuter}>
      <Row>
        <Col>
          {keywords.map(({ keyword, ...datum }) => (
            <KeyWord
              id={keyword}
              key={keyword}
              handleClick={(value) => action && action(value)}
              toolTip={toolTip && toolTip(datum[dataKey])}
            >
              {`${keyword} (${coerceLocaleString(datum[dataKey])})`}
            </KeyWord>))}
        </Col>
      </Row>
    </div>
  );
}

KeywordCardComponent.propTypes = {
  dataset: PropTypes.array,
  action: PropTypes.func,
  dataKey: PropTypes.string,
  toolTip: PropTypes.func,
};

const KeywordCard = compose(
  LayoutCard({
    size: LayoutCard.SHORT,
    containerPadding: 0,
    containerClassName: styles.container,
    showZeroState: true,
  }),
)(KeywordCardComponent);


export default KeywordCard;
