import { App } from '@sixsense/rbac/constants';
import { get } from 'lodash';
import { SI_PlANS, SI_TRAIL_STATUS } from '../../constants';

const {
  PLAN_D,
  PLAN_E,
  PLAN_C,
  PLAN_F,
} = SI_PlANS;

const SUPPORTED_SI_PLANS = {
  authentication: [PLAN_D, PLAN_E],
  contact_details: [PLAN_D, PLAN_E],
  keywords: [PLAN_D, PLAN_E],
  jobtitles: [PLAN_D, PLAN_E, PLAN_C],
  psychographics: [PLAN_D, PLAN_E, PLAN_C],
  talkingpoints: [PLAN_E, PLAN_F],
  taxonomy: [PLAN_E],
};

export const checkSiAccess = (showInfo, card) => {
  const { org = {}, userAppRoles = [] } = showInfo;
  const packagePlanName = get(org, 'packagePlan.SI.plan.name', false);
  const plans = SUPPORTED_SI_PLANS[card] || [];
  switch (card) {
    case 'keywords':
    case 'authentication':
    case 'taxonomy':
    case 'contact_details':
      if (userAppRoles.find((el) => el.app_id === App.ABM)) {
        return true;
      }
      if (packagePlanName) {
        return plans.includes(packagePlanName);
      }
      return true;

    default: return plans.includes(packagePlanName);
  }
};

export const checkSiAppEnabled = (showInfo) => {
  const { org = {} } = showInfo;
  const packagePlan = get(org, 'packagePlan.SI.plan.name', false);
  return !!packagePlan;
};

export const checkWorkflowsAppEnabled = (showInfo) => {
  const { org = {} } = showInfo;
  const hasPackage = !get(org, 'packages.data_workflows.is_deleted', true);

  return hasPackage;
};

export const showLegacySiSettings = (showInfo) => {
  const { si_trial } = showInfo;
  if (checkSiAppEnabled(showInfo)) {
    return si_trial === SI_TRAIL_STATUS.trailInProgress;
  }
  return true;
};


export const getAppUrlWithOrgName = (appEndpoint) => {
  const appHost = new URL(appEndpoint).host;
  const abmProtocolWithOrg = window.location.origin.substring(0, location.origin.indexOf('.'));
  return `${abmProtocolWithOrg}.${appHost}/`;
};
