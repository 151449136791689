
export const AUDIENCE_ROUTES = {
  DEFAULT: {
    PATH: 'workflows',
    TITLE: 'Workflows',
  },
};

export const MULTI_APP_EVENT = {
  LISTENERS: {
    PARENT_TO_ABM_LOAD_APP_SETTINGS_LIST_PAGE: 'PARENT_TO_ABM.loadAppSettingsListPage',
    PARENT_TO_ABM_TO_CHILD_DATA_PASSTHROUGH: 'PARENT_TO_ABM_TO_CHILD.dataPassThrough',
    PARENT_TO_ABM_REDIRECT_TO_ABM_SETTINGS_PATH: 'PARENT_TO_ABM.redirectToABMSettingsPath',
    CHILD_TO_ABM_HEADLESS_APP_UNLOADED: 'CHILD_TO_ABM.headlessAppUnLoaded',
    CHILD_TO_ABM_TO_PARENT_DATA_PASSTHROUGH: 'CHILD_TO_ABM_TO_PARENT.dataPassThrough',
    CHILD_TO_ABM_TO_PARENT_DOCUMENT_CLICKED: 'CHILD_TO_ABM_TO_PARENT.documentClicked',
    PARENT_TO_ABM_UPDATE_ABM_DRAWER_STATE: 'PARENT_TO_ABM.updateABMDrawerState',
    CHILD_TO_ABM_UPDATE_ABM_DRAWER_STATE: 'CHILD_TO_ABM.updateABMDrawerState',
    CHILD_TO_ABM_APPHEADER_CONFIG: 'CHILD_TO_ABM.appHeaderConfig',
  },
  EMITTERS: {
    ABM_TO_CHILD_LOAD_APP_SETTINGS_LISTPAGE: 'ABM_TO_CHILD.loadAppSettingsListPage',
    ABM_TO_CHILD_LOAD_DEFAULT_PAGE: 'ABM_TO_CHILD.loadDefaultPage',
    ABM_TO_PARENT_SETTINGS_DRAWER_LOADED: 'ABM_TO_PARENT.settingsDrawerLoaded',
    ABM_TO_PARENT_SETTINGS_DRAWER_UNLOADED: 'ABM_TO_PARENT.settingsDrawerUnLoaded',
    ABM_TO_PARENT_ACTIVE_PATH_CHANGED: 'ABM_TO_PARENT.ActivePathChanged',
  },
};

export const AUDIENCE_IFRAME_ID = 'audience_iframe_raim';

export const WORKFLOWSUI_ENDPOINT = window.process.env.WORKFLOWS_UI_ENDPOINT;
// export const WORKFLOWSUI_ENDPOINT = 'http://marketotest.localhost.com:8002/';
export const AUDIENCE_WF_DEFAULT_PATH = 'listPlay/AUDIENCE';
