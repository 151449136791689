import React, { Fragment, useEffect, useState } from 'react';
import { AccessDenied } from 'v2-components';
import { compose } from 'redux';
import { withPermissionBoundary } from '@sixsense/rbac';
import { AUDIENCE_WORKFLOWS_PERMISSION } from 'constants/featurePermissions';
import { withRouter } from 'react-router';
import { getCurrentSearchParamValue, trimStartingSlash } from '../utils';
import { AUDIENCE_IFRAME_ID, AUDIENCE_WF_DEFAULT_PATH, WORKFLOWSUI_ENDPOINT } from '../constants';
import AudienceMultiAppEventHandler from '../components/AudienceMultiAppEventHandler';
import { getAppUrlWithOrgName } from 'routes/Settings/routes/Manage/utils';
import { connect } from 'react-redux';
import { isWorkflowsBetaCustomerSelector } from 'modules/user/selectors';


const styles = {
  workflowsIframe: (loading) => loading ? {
    display: 'none',
  } : {
    flex: 1,
    height: '100%',
    width: '100%',
    border: 0,
    display: 'flex',
  },
};

type TAudienceContainer = {
  isWorkflowsBetaCustomer: boolean,
};

const AudienceContainer = (prop: TAudienceContainer) => {
  const { isWorkflowsBetaCustomer } = prop;
  const workflowsEndpoint = getAppUrlWithOrgName(WORKFLOWSUI_ENDPOINT);
  const workflowPath = AUDIENCE_WF_DEFAULT_PATH;
  const [iframeSrc, setIframeSrc] = useState(workflowsEndpoint + workflowPath);

  useEffect(() => {
    const pathFromParams = getCurrentSearchParamValue('activePath');
    if (pathFromParams) {
      setIframeSrc(workflowsEndpoint + trimStartingSlash(pathFromParams));
    }
  }, []);

  if (!isWorkflowsBetaCustomer) { // @johniaconis to change the workflows_beta flag to audience_beta
    return <AccessDenied accessDenied />;
  }

  return (
    <Fragment>
      <AudienceMultiAppEventHandler>
        {(loading) => <iframe
          id={AUDIENCE_IFRAME_ID}
          src={iframeSrc}
          style={styles.workflowsIframe(loading)}
        />}
      </AudienceMultiAppEventHandler>
    </Fragment>
  );
};


const mapStateToProps = (state) => ({
  isWorkflowsBetaCustomer: isWorkflowsBetaCustomerSelector(state),
});

export default compose(
  connect(mapStateToProps, null),
  withPermissionBoundary({
    allow: (permissions) => AUDIENCE_WORKFLOWS_PERMISSION.some(
      (permission) => permissions.has(permission)
    ),
    renderDenied: () => <AccessDenied accessDenied />,
  }),
  withRouter
)(AudienceContainer);
