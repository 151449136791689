import { createSelector } from 'reselect';
import { orgFlagsSelector, orgObjSelector } from 'modules/user/selectors';
import { loadGenericIntgrationStatusGenerator,
  lastFileDropTimeStampGenerator } from './stateGenerators';
import { getDateTimestampInUTCFormat } from './utils';
import { get } from 'lodash';

export const formStateSelector = (state) => state.form;
export const formSelector = (formName) =>
  createSelector(formStateSelector, (formState) =>
    formState[formName] ? formState[formName] : null
  );

export const integrationsRootSelector = (state) => state.integrationsRoot;

export const isHubSpotAccessTokenEnabledSelector = createSelector(
  orgFlagsSelector,
  ({ hubspot_accesstoken_enabled = false }) => hubspot_accesstoken_enabled
);

export const genericStatusSelector = (state) =>
loadGenericIntgrationStatusGenerator.dataSelector(state);

export const lastFileDropTimeStampSelector = (state) => {
  const timestamp = lastFileDropTimeStampGenerator.dataSelector(state);
  return timestamp ? getDateTimestampInUTCFormat(timestamp) : null;
};

export const isPredictivePackageSelector = createSelector(
  orgObjSelector,
  ({ packages } = {}) => !get(packages, 'predictive.is_deleted', true)
);
